import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Button
        onClick={() => {
          navigate(-1);
        }}
        icon={<i className="fa-duotone fa-chevron-left"></i>}
      />
    </div>
  );
};

export default BackButton;
