import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Provider, useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { TableModel } from "../../../services/tables/models/tabele.model";
import { Button, Modal, QRCode, Table, TableColumnsType } from "antd";
import Title from "antd/es/typography/Title";
import { TableService } from "../../../services/tables/table.service";
import CompManagerTableAdd from "./CompManagerTableAdd";
import { SectionService } from "../../../services/sections/serction.service";
import { SectionModel } from "../../../services/sections/models/section.model";
import { toast } from "react-toastify";
import { ApiUrl } from "../../../consts/api";
import { store } from "../../../react-reduxs/configure_store";

const CompManagerTableList = () => {
  const companyId = useSelector(getCompanyId);
  const tableService = useMemo(() => new TableService(), []);
  const sectionService = useMemo(() => new SectionService(), []);

  const [count, setCount] = useState(0);
  const [openModalCompanyAdd, setOpenModalCompanyAdd] =
    useState<boolean>(false);
  const [data, setData] = useState<TableModel[]>([]);
  const [sectionList, setSectionList] = useState<SectionModel[]>([]);
  const domainUrl = window.location.origin;
  const columns: TableColumnsType<TableModel> = [
    {
      width: 40,
      title: "Fotoğraf",
      dataIndex: "logo",
      render: (value) => {
        return (
          <img
            width={50}
            height={50}
            src={
              value !== undefined ? ApiUrl + "/" + value : "/images/table.png"
            }
            alt=""
          />
        );
      },
    },
    {
      title: "Adı",
      dataIndex: "name",
    },
    {
      title: "Masa Numarası",
      dataIndex: "number",
    },
    {
      title: "Kare kod oluştur",
      dataIndex: "id",
      render: (value) => {
        return (
          <>
            <Button
              onClick={() => {
                Modal.success({
                  content: (
                    <QRCode
                      size={300}
                      errorLevel="H"
                      value={`https://menu.qrmenuscan.com/${value}`}
                      icon="/images/Backlink.png"
                    />
                  ),
                });
              }}
              icon={<i className="fa-light fa-qrcode"></i>}
            />
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      width: 50,
      render: (value) => {
        return (
          <Button
            type="text"
            onClick={() => {
              Modal.info({
                okText: "İptal",
                okType: "danger",
                content: (
                  <Provider store={store}>
                    <CompManagerTableAdd
                      getTableList={getTable}
                      tableService={tableService}
                      id={value}
                      add={addTable}
                      addSection={addSection}
                      sectionList={sectionList}
                    />
                  </Provider>
                ),
              });
            }}
            icon={
              <i
                style={{ color: "green", fontSize: "large" }}
                className="fa-duotone fa-pen-to-square"
              ></i>
            }
          />
        );
      },
    },
  ];
  const getSection = useCallback(async () => {
    var response = await sectionService.getListByCompanyId(companyId);
    if (response) {
      setSectionList(response);
    }
  }, [companyId, sectionService]);

  const getTable = useCallback(async () => {
    var response = await tableService.getListByCompanyId(companyId);
    if (response) {
      setData(response);
    }
  }, [companyId, tableService]);
  const addSection = useCallback(
    async (sectionModel: SectionModel) => {
      if (sectionModel) {
        let response = await sectionService.add(sectionModel);
        if (response) {
          toast.success("Kayıt işlemi başarılı");
          await getSection();
        }
      }
    },
    [getSection, sectionService]
  );
  const addTable = useCallback(
    async (tableModel: TableModel) => {
      if (tableModel.id === "") {
        let response = await tableService.add(tableModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getTable();
        }
      } else {
        let response = await tableService.update(tableModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getTable();
        }
      }
    },
    [getTable, tableService]
  );

  const initLoad = useCallback(async () => {
    await getSection();
    await getTable();
  }, [getSection, getTable]);

  useEffect(() => {
    if (companyId) {
      setCount((cou) => {
        if (cou === 0) {
          initLoad();
        }
        return 1;
      });
    }
    return () => {};
  }, [companyId, initLoad]);

  return (
    <div>
      <Title level={3} style={{ color: "#4A5967", textAlign: "center" }}>
        Masa Listesi
      </Title>
      <Button
        onClick={() => {
          setOpenModalCompanyAdd(true);
        }}
        size="small"
        style={{ margin: 10 }}
        icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
      >
        Yeni Masa Ekle
      </Button>
      <Table columns={columns} dataSource={data} rowKey={"id"} />
      {openModalCompanyAdd ? (
        <Modal
          title=""
          open={openModalCompanyAdd}
          okText={undefined}
          cancelText={undefined}
          onCancel={() => setOpenModalCompanyAdd(false)}
          styles={{ mask: { backdropFilter: "blur(10px)" } }}
          footer={<></>}
        >
          <CompManagerTableAdd
            tableService={tableService}
            add={addTable}
            addSection={addSection}
            sectionList={sectionList}
          />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompManagerTableList;
