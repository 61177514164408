import { Avatar, FloatButton, Popconfirm, Space, Tooltip } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BasketComponent from "../../../components/baskets/BasketComponent";
import { I18nPagePropertyEnum } from "../../../i18n/i18n_page_property";
import CompanyTableOrderPage from "../tables/CompanyTableOrderPage";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../react-reduxs/configure_store";
import "./template2.css";
import Template2ProductList from "../components/Template2ProductList";
import { getCompany } from "../../../react-reduxs/slices/companies/company_slice";
import { OrderService } from "../../../services/orders/order.service";
import CompanyLogoComponent from "../components/CompanyLogoComponent";
import { ApiUrl, WaiterHubUrl } from "../../../consts/api";
import { getTableId } from "../../../react-reduxs/slices/tables/tableSlice";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import * as signalR from "@microsoft/signalr";
const Template2 = () => {
  const company = useSelector(getCompany);
  const orderService = useMemo(() => new OrderService(), []);
  const companyId = useSelector(getCompanyId);
  const tableId = useSelector(getTableId);
  const { t } = useTranslation();

  const [tooltipVisble, setTooltipVisble] = useState<boolean>(false);
  const [floatVisible, setFloatVisible] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);

  const tooltipChange = useCallback((value: boolean) => {
    setTooltipVisble(value);
  }, []);

  useEffect(() => {
    setCount((cn) => {
      if (cn === 0) {
      }
      return 1;
    });

    setTimeout(() => {
      tooltipChange(true);
      setTimeout(() => {
        tooltipChange(false);
      }, 2000);
    }, 500);

    return () => {};
  }, [tooltipChange]);

  return (
    <div
      style={
        company?.backgroundImage
          ? {
              backgroundBlendMode: "color-dodge",
              backgroundSize: "cover",
              height: "100vh",
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("${ApiUrl+"/"+company.backgroundImage}")`,
            }
          : {
              height: "100vh",
              backgroundBlendMode: "color-dodge",
              backgroundSize: "cover",
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("https://www.shutterstock.com/blog/wp-content/uploads/sites/5/2020/02/Usign-Gradients-Featured-Image.jpg")`,
            }
      }
    >
      <div
        style={{
          backgroundColor: "#FFFFFFA8",
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          marginBottom: 4,
        }}
      >
        <CompanyLogoComponent />
      </div>
      <Template2ProductList />

      {company?.orderAtTheTable ? (
        <FloatButton.Group
          open={floatVisible}
          trigger="click"
          type="default"
          shape="square"
          icon={<i className="fa-duotone fa-bars fa-xl"></i>}
          closeIcon={<i className="fa-regular fa-xmark fa-xl"></i>}
          onClick={() => {
            if (floatVisible) {
              setFloatVisible(false);
              tooltipChange(false);
            } else {
              setFloatVisible(true);
              setTimeout(() => {
                tooltipChange(true);
                setTimeout(() => {
                  tooltipChange(false);
                }, 2000);
              }, 500);
            }
          }}
          style={{ right: 16, padding: 0 }}
          badge={{ dot: true }}
        >
          <Tooltip
            open={tooltipVisble}
            placement="left"
            title={t(I18nPagePropertyEnum.templateBasketFloat)}
          >
            <BasketComponent />
          </Tooltip>
          {company?.waiterCall ? (
            <Tooltip
              open={tooltipVisble}
              placement="left"
              title={t(I18nPagePropertyEnum.templateWaiterFloat)}
            >
              <Popconfirm
                icon={
                  <i
                    style={{ color: "black", paddingRight: 5 }}
                    className="fa-duotone fa-user-chef fa-lg"
                  ></i>
                }
                placement="leftTop"
                title={t(I18nPagePropertyEnum.menuProductWaiter)}
                description={t(I18nPagePropertyEnum.menuProductCallTheWaiter)}
                okText={t(I18nPagePropertyEnum.commonOk)}
                cancelText={t(I18nPagePropertyEnum.commonCancel)}
                onConfirm={async () => {
                  console.error(companyId, tableId);

                  const connection = new signalR.HubConnectionBuilder()
                    .withUrl(WaiterHubUrl(companyId).url)
                    .configureLogging(signalR.LogLevel.Information)
                    .build();

                  try {
                    await connection
                      .start()
                      .then(() => {
                        console.log("SignalR bağlantısı kuruldu.WaiterHub");
                      })
                      .catch((error) => {
                        console.error(
                          "SignalR bağlantısı başlatılırken hata oluştu:",
                          error
                        );
                      });
                    await connection.invoke(
                      "CallTheWaiter",
                      companyId,
                      tableId
                    );
                  } catch (error) {
                  } finally {
                    connection.stop();
                  }
                }}
              >
                <Space
                  style={{
                    cursor: "pointer",

                    margin: 5,
                  }}
                >
                  <Avatar
                    style={{ backgroundColor: "white" }}
                    shape="square"
                    icon={
                      <i
                        style={{ color: "black" }}
                        className="fa-duotone fa-user-chef fa-lg"
                      ></i>
                    }
                  />
                </Space>
              </Popconfirm>
            </Tooltip>
          ) : (
            <></>
          )}
          <Tooltip
            open={tooltipVisble}
            placement="left"
            title={t(I18nPagePropertyEnum.templateTableFloat)}
          >
            <CompanyTableOrderPage />
          </Tooltip>
        </FloatButton.Group>
      ) : (
        <></>
      )}
    </div>
  );
};
//18 mart 17 59
export default Template2;
