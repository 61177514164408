import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CompanyPublicModel } from "../../../services/companies/models/company_public.model";
import { RootState } from "../../configure_store";

interface CompanyState {
  companyPublic?: CompanyPublicModel;
}

const initialState: CompanyState = {
    
};
const companySlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setCompanyPublic: (state, action: PayloadAction<CompanyPublicModel|undefined>) => {
      state.companyPublic = action.payload;
    },
  },
});

export const {  setCompanyPublic } = companySlice.actions;

export const getCompany = (state: RootState) => state.company.companyPublic;

const CompanyReducer = companySlice.reducer;
export default CompanyReducer;
