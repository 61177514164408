import { Card } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { MenuCategoryModel } from "../../../services/menu_categories/models/menu_category.model";

interface Template2CategoryListProps {
  categoryList: MenuCategoryModel[];
  onSelect: (id: number) => void;
  selectedCategory: number;
}
const Template2CategoryList: React.FC<Template2CategoryListProps> = ({
  categoryList,
  onSelect,
  selectedCategory,
}) => {
  return (
    <div
      style={{
        display: "flex",
        overflowX: "auto",
        overflowY: "hidden",
        gap: 4,
        padding: 2,
      }}
    >
      <Card
        style={{
          width: 130,
          minWidth: 130,

          overflow: "auto",
          cursor: "pointer",
          background: "#ffffffa6",
        }}
        onClick={() => onSelect(0)}
        styles={{
          body: {
            padding: 0,
            backgroundColor:
              selectedCategory === 0 ? "#fcc82761" : "transparent",
          },
        }}
      >
        <div
          style={{
            backgroundSize: "cover",
            height: 65,
            width: "100%",
            backgroundImage:
              "url(https://cdn.kisikates.com.tr/image-cache/cache/post_main_image_large/https---cdn.kisikates.com.tr/post-main-image/db07aaad84553e94bd6bc8a2ed064071de320950.jpg)",
          }}
        ></div>

        <Title
          style={{
            height: "100%",

            textAlign: "center",
            fontSize: "10pt",
            margin: 0,
            marginBottom: 8,
            fontWeight: "bold",
            fontFamily: "GilroyRegular",
            letterSpacing: "1pt",
          }}
        >
          Tüm Ürünler
        </Title>
      </Card>
      {categoryList.flatMap((category) => (
        <Card
          onClick={() => onSelect(category.id)}
          key={category.id}
          style={{
            width: 130,
            minWidth: 130,
            overflow: "auto",
            cursor: "pointer",
            background: "#ffffffa6",
          }}
          styles={{
            body: {
              padding: 0,
              backgroundColor:
                selectedCategory === category.id ? "#fcc82761" : "transparent",
            },
          }}
        >
          <div
            style={{
              backgroundSize: "cover",
              height: 65,
              width: "100%",
              backgroundImage: category.photo
                ? category.photo.includes("image:")
                  ? category.photo.replaceAll("image:", "")
                  : "url(https://www.kargem.online/dosyalar/2020/03/Kategori-Yo%CC%88netimi.jpg)"
                : "url(https://www.kargem.online/dosyalar/2020/03/Kategori-Yo%CC%88netimi.jpg)",
            }}
          ></div>

          <Title
            style={{
              height: "100%",

              textAlign: "center",
              fontSize: "10pt",
              margin: 0,
              marginBottom: 8,
              fontWeight: "bold",
              fontFamily: "GilroyRegular",
              letterSpacing: "1pt",
            }}
          >
            {category.name}
          </Title>
        </Card>
      ))}
    </div>
  );
};

export default Template2CategoryList;
