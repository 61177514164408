import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GroupModel } from "../../../services/groups/models/group.model";
import { Button, Modal, Popconfirm, Table, TableColumnsType } from "antd";
import Title from "antd/es/typography/Title";
import AdminGroupAdd from "./AdminGroupAdd";
import { GroupService } from "../../../services/groups/group.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AdminGroupList: React.FC = () => {
  const groupService = useMemo(() => new GroupService(), []);
  const navigate = useNavigate();
  const [openModalGroupAdd, setOpenModalGroupAdd] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [data, setData] = useState<GroupModel[]>([]);

  const columns: TableColumnsType<GroupModel> = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Grub Adı",
      dataIndex: "groupName",
    },
    {
      title: "",
      width: 50,
      render(value, record, index) {
        return (
          <>
            <Popconfirm
              placement="left"
              title={"Silme İşlemini Onaylıyormusunuz ?"}
              okText="Evet"
              cancelText="Hayır"
              onConfirm={async () => {
                var response = await groupService.deleteById(record.id);
                if (response) {
                  toast.success(response);
                  await getGroupList();
                }
              }}
            >
              <Button
                type="text"
                icon={
                  <i
                    style={{ color: "red", fontSize: "large" }}
                    className="fa-duotone fa-trash "
                  ></i>
                }
              />
            </Popconfirm>
          </>
        );
      },
    },
    {
      title: "",
      width: 50,
      render(value, record, index) {
        return (
          <>
            <Button
              type="text"
              onClick={() => {
                navigate(`/admin/group-operation-claim/${record.id}`);
              }}
              icon={
                <i
                  style={{ color: "#1677FF", fontSize: "large" }}
                  className="fa-duotone fa-shield-check"
                ></i>
              }
            />
          </>
        );
      },
    },
  ];

  const getGroupList = useCallback(async () => {
    var response = await groupService.list();
    if (response) {
      setData(response);
    }
  }, [groupService]);

  const initialLoad = useCallback(async () => {
    await getGroupList();
  }, [getGroupList]);
  useEffect(() => {
    setCount((co) => {
      if (co === 0) {
        initialLoad();
      }
      return 1;
    });

    return () => {};
  }, [initialLoad]);

  const addGroup = useCallback(
    async (groupModel: GroupModel) => {
      var response = await groupService.addGroup(groupModel);
      console.error(response);

      if (response) {
        toast.success("Ekleme İşlemi Başarılı");
        await getGroupList();
        setOpenModalGroupAdd(false);
      }
    },
    [getGroupList, groupService]
  );

  return (
    <div>
      <Title level={3} style={{ color: "#4A5967", textAlign: "center" }}>
        Kullanıcı Grub Listesi
      </Title>
      <Button
        onClick={() => {
          setOpenModalGroupAdd(true);
        }}
        size="small"
        style={{ margin: 10 }}
        icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
      >
        Yeni Grub Ekle
      </Button>
      <Table columns={columns} dataSource={data} rowKey={"id"} />
      <Modal
        title=""
        open={openModalGroupAdd}
        okText={undefined}
        cancelText={undefined}
        onCancel={() => setOpenModalGroupAdd(false)}
        styles={{ mask: { backdropFilter: "blur(10px)" } }}
        footer={<></>}
      >
        <AdminGroupAdd groupAdd={addGroup} />
      </Modal>
    </div>
  );
};

export default AdminGroupList;
