import { Dropdown } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { I18nPagePropertyEnum } from "../i18n/i18n_page_property";
import { getToken, loginOut } from "../react-reduxs/slices/auth/auth_slice";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../react-reduxs/configure_store";

const UserProfileComponent = () => {
  const token = useSelector(getToken);
  const [userName, setUserName] = useState<string>();
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    if (token) {
      let decodeToken = JSON.parse(atob(token.split(".")[1]));
      if (decodeToken) {
        setUserName(
          decodeToken[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
          ]
        );
      }
    }
    return () => {};
  }, [token]);
  return (
    <Dropdown.Button
      menu={{
        items: [
          {
            key: 1,
            label: t(I18nPagePropertyEnum.commonLogOut),
            icon: <i className="fa-duotone fa-arrow-left-from-arc"></i>,
            onClick: () => dispatch(loginOut()),
          },
        ],
      }}
      placement="bottom"
      icon={<i className="fa-duotone fa-user"></i>}
    >
      {userName ?? " name found"}
    </Dropdown.Button>
  );
};

export default UserProfileComponent;
