import { Button } from "antd";
import Title from "antd/es/typography/Title";
import "./counter.css";
import { useSelector } from "react-redux";
import { getCompany } from "../../../react-reduxs/slices/companies/company_slice";

const CounterComponent = ({
  count = 1,
  increment = () => {},
  descrement = () => {},
}) => {
  const company = useSelector(getCompany);

  if (company?.orderAtTheTable) {
    return (
      <div className="counter-content">
        <Button
          style={{
            fontSize: "smaller",
            background: "#2a283d00",
            borderColor: "#d9d9d900",
          }}
          size="small"
          icon={
            <i
              style={{ color: "white" }}
              className="fa-duotone fa-minus fa-sm"
            ></i>
          }
          onClick={descrement}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
            borderRadius: 5,
          }}
        >
          <Title className="counter" style={{ fontSize: "smaller" }} level={5}>
            {count}
          </Title>
        </div>
        <Button
          style={{
            fontSize: "smaller",
            background: "#d3d3d300",
            borderColor: "#d9d9d900",
          }}
          size="small"
          icon={
            <i style={{ color: "white" }} className="fa-duotone fa-plus"></i>
          }
          onClick={increment}
        />
      </div>
    );
  }

  return <></>;
};

export default CounterComponent;
