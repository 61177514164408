import BaseApiService from "../base_api.service";
import { MenuProductModel } from "./models/menu_product.model";

class MenuProductServcie extends BaseApiService {
  constructor() {
    super("MenuProducts");
  }
  async getList(companyId: number): Promise<MenuProductModel[]> {
    return (await this.get(`?companyId=${companyId}`)) ?? [];
  }
  add(
    menuProductModel: MenuProductModel
  ): Promise<MenuProductModel | undefined> {
    return this.post<MenuProductModel>(``, menuProductModel);
  }
  getById(id: number): Promise<MenuProductModel | undefined> {
    return this.get<MenuProductModel>(`/${id}`);
  }
  update(
    menuProductModel: MenuProductModel
  ): Promise<MenuProductModel | undefined> {
    return this.put<MenuProductModel>(``, menuProductModel);
  }
}
export default MenuProductServcie;
