import { I18nPageProperty } from "../i18n_page_property";

const En = {
  waiterLayout: { order: I18nPageProperty.waiterLayout.order.en },
  common: {
    pendingApproval: I18nPageProperty.common.pendingApproval.en,
    approved: I18nPageProperty.common.approved.en,
    itIsCancelled: I18nPageProperty.common.itIsCancelled.en,
    orderIsBeingPrepared: I18nPageProperty.common.orderIsBeingPrepared.en,
    ok: I18nPageProperty.common.ok.en,
    cancel: I18nPageProperty.common.cancel.en,
    orders: I18nPageProperty.common.orders.en,
    amount: I18nPageProperty.common.amount.en,
    logOut: I18nPageProperty.common.logOut.en,
    sendOrder: I18nPageProperty.common.sendOrder.en,
    paymentNotMade:I18nPageProperty.common.paymentNotMade.en,
    paymentCompleted:I18nPageProperty.common.paymentCompleted.en,
  },
  waiter: {
    confirmAllOrders: I18nPageProperty.waiter.confirmAllOrders.en,
    tableDetailTitle: I18nPageProperty.waiter.tableDetailTitle.en,
    theWaiterIsCalling: I18nPageProperty.waiter.theWaiterIsCalling.en,
  },
  login: {
    title: I18nPageProperty.login.title.en,
    userName: I18nPageProperty.login.userName.en,
    password: I18nPageProperty.login.password.en,
    email: I18nPageProperty.login.email.en,
    login: I18nPageProperty.login.login.en,
    language: I18nPageProperty.login.language.en,
  },
  menuProduct: {
    orderNote: I18nPageProperty.menuProduct.orderNote.en,
    addToBasket: I18nPageProperty.menuProduct.addToBasket.en,
    waiter: I18nPageProperty.menuProduct.waiter.en,
    callTheWaiter: I18nPageProperty.menuProduct.callTheWaiter.en,
  },
  template: {
    waiterFloat: I18nPageProperty.template.waiterFloat.en,
    basketFloat: I18nPageProperty.template.basketFloat.en,
    tableFloat: I18nPageProperty.template.tableFloat.en,
  },
};
export default En;
