import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Steps,
} from "antd";
import Title from "antd/es/typography/Title";
import { CompanyModel } from "../../../services/companies/models/company.model";
import { CurrencyModel } from "../../../services/currencies/models/currency.model";
import { CurrencyService } from "../../../services/currencies/currency.service";
import { CompanyService } from "../../../services/companies/company.service";
import AdminCompanyUserAdd from "./AdminCompanyUserAdd";
import { v4 as uuidv4 } from "uuid";
import FileUploadComponent from "../../tests/FileUploadComponent";
import { ApiUrl } from "../../../consts/api";
import { FileUploadService } from "../../../services/file_uploads/file_upload.service";

interface AdminCompanyAddProps {
  companyService: CompanyService;
  id?: number;
  add: (value: CompanyModel) => Promise<CompanyModel | undefined>;
}

const AdminCompanyAdd: React.FC<AdminCompanyAddProps> = ({
  add,
  id,
  companyService,
}) => {
  const fileUploadService = new FileUploadService();
  const currencyService = useMemo(() => new CurrencyService(), []);
  const [deleteCompanyLogo, setDeleteCompanyLogo] = useState("");
  const [deleteCompanyBackground, setDeleteCompanyBackground] = useState("");
  const [templateList, setTemplateList] = useState<any[]>([
    {
      id: 1,
      text: "Şablon 1",
    },
    {
      id: 2,
      text: "Şablon 2",
    },
    {
      id: 3,
      text: "Şablon 3",
    },
  ]);
  const [count, setCount] = useState(0);
  const [currencyList, setCurrencyList] = useState<CurrencyModel[]>([]);
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [companyId, setCompanyId] = useState(0);
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyBackground, setCompanyBackground] = useState("");
  const onFinish = async (values: any) => {
    if (values.folderName === "" || values.folderName === undefined) {
      const guid = uuidv4();
      form.setFieldValue("folderName", guid);
    }

    let response = await add({
      ...values,
     folderName:form.getFieldValue("folderName"),
      logo: companyLogo,
      backgroundImage: companyBackground,
    });
    if (response) {
      if (response.id !== 0) {
        if (deleteCompanyLogo !== "" && deleteCompanyLogo !== undefined) {
          fileUploadService.deleteFile(deleteCompanyLogo);
        }
        setCompanyId(response.id);
        form.setFieldValue("id", response.id);
        next();
      }
      return response;
    }
  };

  const getCurrencyList = useCallback(async () => {
    let response = await currencyService.getList();

    if (response) {
      setCurrencyList(response);
    }
  }, [currencyService]);

  const getCompanyById = useCallback(async () => {
    let response = await companyService.getByCompanyId(id ?? 0);

    if (response) {
      console.error(response);
      
      setCompanyId(response.id);
      form.setFieldValue("id", response.id);
      form.setFieldValue("title", response.title);
      form.setFieldValue("folderName", response.folderName);
      form.setFieldValue("connection", response.connection);
      form.setFieldValue("subheading", response.subheading);
      form.setFieldValue("currencyId", response.currencyId);
      form.setFieldValue("waiterCall", response.waiterCall);
      form.setFieldValue("templateId", response.templateId);
      form.setFieldValue("orderAtTheTable", response.orderAtTheTable);
      setCompanyBackground(response.backgroundImage);
      setCompanyLogo(response.logo);
      setCurrent(current + 1);
    }
  }, [companyService, current, form, id]);

  const initload = useCallback(async () => {
    if (id !== 0) {
      await getCompanyById();
    }
    await getCurrencyList();
  }, [getCompanyById, getCurrencyList, id]);

  useEffect(() => {
    setCount((cou) => {
      if (cou === 0) {
        initload();
      }
      return 1;
    });
    return () => {};
  }, [initload]);
  const delteCompanyLogoOnclick = useCallback(() => {
    setDeleteCompanyLogo(companyLogo);
    setCompanyLogo("");
  }, [companyLogo]);
  const deleteCompanyBackgroundOnclick = useCallback(() => {
    setDeleteCompanyBackground(companyBackground);
    setCompanyBackground("")
  }, [companyBackground]);

  const onFinishFailed = () => {};
  const next = () => {
    setCurrent(1);
  };

  const prev = () => {
    setCurrent(0);
  };

  return (
    <div>
      <Steps
        current={current}
        direction="vertical"
        items={[
          {
            title: "Firma Oluştur",
            description: (
              <>
                <div>
                  <Title level={3}>Firma Ekle</Title>
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Row style={{ gap: 3 }}>
                      <Form.Item
                        name="id"
                        label=""
                        rules={[{ required: true }]}
                        initialValue={id ?? 0}
                      >
                        <InputNumber
                          style={{ display: "none" }}
                          placeholder=""
                        />
                      </Form.Item>
                      <Col style={{ marginRight: 20 }} flex={1}>
                        <Title style={{ margin: 0 }} level={5}>
                          Firma Logo
                        </Title>
                        {companyLogo !== undefined && companyLogo !== "" ? (
                          <>
                            <img
                              width={100}
                              src={ApiUrl + "/" + companyLogo}
                              alt=""
                            />
                            <Button
                              size="small"
                              onClick={delteCompanyLogoOnclick}
                              danger
                              icon={<i className="fa-duotone fa-trash"></i>}
                            />
                          </>
                        ) : (
                          <FileUploadComponent
                            path={"Companies/Images"}
                            setImageUrlParam={setCompanyLogo}
                          />
                        )}
                      </Col>
                      <Col flex={1}>
                        <Title
                          style={{
                            margin: 0,
                            fontWeight: "normal",
                            marginBottom: 8,
                          }}
                          level={5}
                        >
                          Arka plan fotoğrafı
                        </Title>
                        {companyBackground !== undefined &&
                        companyBackground !== "" ? (
                          <>
                            <img
                              width={100}
                              src={ApiUrl + "/" + companyBackground}
                              alt=""
                            />
                            <Button
                              size="small"
                              onClick={deleteCompanyBackgroundOnclick}
                              danger
                              icon={<i className="fa-duotone fa-trash"></i>}
                            />
                          </>
                        ) : (
                          <FileUploadComponent
                            path={"Companies/Images"}
                            setImageUrlParam={setCompanyBackground}
                          />
                        )}
                      </Col>
                      <Col flex={1}>
                        <Form.Item
                          name="title"
                          label="Başlık"
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Başlık" />
                        </Form.Item>
                      </Col>
                      <Col flex={1}>
                        <Form.Item name="connection" label="Bağlantı">
                          <Input placeholder="Bağlantı" />
                        </Form.Item>
                      </Col>

                      <Col flex={1}>
                        <Form.Item name="subheading" label="Alt başlık">
                          <Input placeholder="Alt başlık" />
                        </Form.Item>
                      </Col>
                      <Col flex={1}>
                        <Form.Item name="folderName" label="Klasör adı">
                          <Input disabled placeholder="Klasör adı" />
                        </Form.Item>
                      </Col>
                      <Col flex={1}>
                        <Form.Item
                          name="currencyId"
                          label="Para birimi "
                          rules={[{ required: true }]}
                        >
                          <Select
                            placeholder=" "
                            style={{ width: "100%", minWidth: 150 }}
                            options={currencyList.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex={1}>
                        <Form.Item
                          name="templateId"
                          label="Şablonlar"
                          rules={[{ required: true }]}
                        >
                          <Select
                            placeholder=" "
                            style={{ width: "100%", minWidth: 150 }}
                            options={templateList.map((item) => ({
                              value: item.id,
                              label: item.text,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex={1}>
                        <Form.Item
                          name="waiterCall"
                          valuePropName="checked"
                          label="Garson çağırmaya izinver"
                          initialValue={true}
                        >
                          <Checkbox>Durum</Checkbox>
                        </Form.Item>
                      </Col>
                      <Col flex={1}>
                        <Form.Item
                          name="orderAtTheTable"
                          valuePropName="checked"
                          label="Şiparise izinver"
                          initialValue={true}
                        >
                          <Checkbox>Durum</Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item>
                      <Space>
                        <Button type="primary" htmlType="submit">
                          Kaydet
                        </Button>
                      </Space>
                    </Form.Item>
                  </Form>
                </div>
              </>
            ),
          },
          {
            title: "Kullanıcı Ekle",
            description:
              current !== 0 ? (
                <AdminCompanyUserAdd companyId={companyId} />
              ) : (
                <></>
              ),
          },
        ]}
      />
    </div>
  );
};

export default AdminCompanyAdd;
