import BaseApiService from "../base_api.service";
import { TableModel } from "./models/tabele.model";

export class TableService extends BaseApiService {
  /**
   *
   */
  constructor() {
    super("Tables");
  }

  async getListByCompanyId(companyId: number): Promise<TableModel[]> {
    var dddd = await this.get<TableModel[]>(
      `/GetTableListByCompanyId?companyId=${companyId}`
    );

    return dddd ?? [];
  }
  add(tableModel: TableModel): Promise<TableModel | undefined> {
    return this.post<TableModel>(``, tableModel);
  }
  update(tableModel: TableModel): Promise<TableModel | undefined> {
    return this.put<TableModel>(``, tableModel);
  }
  getById(id:string):Promise<TableModel | undefined>{
return this.get<TableModel>(`/GetTableById?id=${id}`)
  }
}
