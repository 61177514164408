enum LanguageEnum {
  tr = "tr",
  en = "en",
}

type LanguageEnumType = keyof typeof LanguageEnum;

declare global {
  interface String {
    toText(): string;
  }
}

String.prototype.toText = function (this: LanguageEnumType): string {
  switch (this) {
    case LanguageEnum.tr:
      return "Türkçe";
    case LanguageEnum.en:
      return "English";
    default:
      return "";
  }
};
export { LanguageEnum };
