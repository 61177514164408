import { Badge } from "antd";
import React from "react";
import { selectBasketList } from "../../../react-reduxs/slices/baskets/basker-slice";
import { useSelector } from "react-redux";

interface BasketBadgeProps {
  children?: React.ReactNode;
}

const BasketBadgeComponent: React.FC<BasketBadgeProps> = ({
  children = <></>,
}) => {
  const basketList = useSelector(selectBasketList);
 
  
  return <Badge count={basketList.length}>{children}</Badge>;
};

export default BasketBadgeComponent;
