import { useDispatch, useSelector } from "react-redux";

import { UserTypeEnum } from "./enums/user_type.enum";
import { AppDispatch } from "./react-reduxs/configure_store";
import {
  getToken,
  loginOut,
  setToken,
} from "./react-reduxs/slices/auth/auth_slice";
import { useEffect } from "react";

type Props = {
  userType: UserTypeEnum;
  children: React.ReactNode;
};

function PrivateRoute({ userType, children, ...rest }: Props) {
  const token = useSelector(getToken);
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    if (token !== "") {
      let tokenDecodd = JSON.parse(atob(token.split(".")[1]));

      if (tokenDecodd?.userType) {
        if (tokenDecodd.userType !== userType) {
          dispatch(loginOut());
          window.location.href = "/login";
        }
      } else {
        dispatch(loginOut());
        window.location.href = "/login";
      }
      if (tokenDecodd.userType !== userType) {
        dispatch(setToken(""));
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
    return () => {};
  }, [dispatch, token]);

  return <>{token !== "" ? children : <></>}</>;
}

export default PrivateRoute;
