export enum OrderHubEnum{
    placeOrder="PlaceOrder",
    orderProductStatusUpdate="OrderProductStatusUpdate",
    orderClose="OrderClose",
    waiterOrderList="WaiterOrderList",
    receiveOrder="ReceiveOrder",
    /**
     * Siparişi tablodan masadan
     */
    removeOrderTable="RemoveOrderTable"
}