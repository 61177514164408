import { OrderStateModel } from "./models/order_state.model";
import BaseApiService from "../base_api.service";
import { AddOrderProductModel } from "./models/add_order_product.model";

export class OrderService extends BaseApiService {
  /**
   *
   */
  constructor() {
    super("orders");
  }

  async getOrder(companyId: number, tableId: string): Promise<OrderStateModel|undefined> {
    return await this.get<OrderStateModel>(
      `/GetOrderByTableId?companyId=${companyId}&tableId=${tableId}`
    );
  }
  async getOrderList(companyId: number): Promise<OrderStateModel[]> {
    return await this.get<OrderStateModel[]>(`?companyId=${companyId}`)??[];
  }
  async addOrder(
    tableId: number,
    menuProductList: AddOrderProductModel[]
  ): Promise<any> {
    return {};
  }
}
