import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CurrencyModel } from "../../../services/currencies/models/currency.model";
import { Button, Modal, Popconfirm, Table, TableColumnsType } from "antd";
import Title from "antd/es/typography/Title";
import AdminCurrencyAdd from "./AdminCurrencyAdd";
import { CurrencyService } from "../../../services/currencies/currency.service";
import { toast } from "react-toastify";

const AdminCurrencyList: React.FC = () => {
  const currencyService = useMemo(() => new CurrencyService(), []);
  const id = useRef(0);
  const [openModalCurrencyAdd, setOpenModalCurrencyAdd] =
    useState<boolean>(false);
  const [data, setData] = useState<CurrencyModel[]>([]);
  const [count, setCount] = useState(0);

  const getCurrencyList = useCallback(async () => {
    let response = await currencyService.getList();
    if (response) {
      setData(response);
    }
  }, [currencyService]);

  const initLoad = useCallback(async () => {
    await getCurrencyList();
  }, [getCurrencyList]);

  useEffect(() => {
    try {
      setCount((cou) => {
        if (cou === 0) {
          initLoad();
        }
        return 1;
      });
    } catch (error) {}
    return () => {};
  }, [initLoad]);

  const addCurrency = useCallback(
    async (currencyModel: CurrencyModel) => {
      if (currencyModel.id === 0) {
        let response = await currencyService.add(currencyModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getCurrencyList();
        }
      } else {
        let response = await currencyService.update(currencyModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getCurrencyList();
        }
      }
    },
    [currencyService, getCurrencyList]
  );
  function convertToUnicode(value: string) {
    const unicodeValue = value.replace(/\\u[\dA-F]{4}/gi, (match) => {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
    });
    return unicodeValue;
  }
  const deleteCurrency = useCallback(
    async (id: number) => {
      let response = await currencyService.deleted(id);
      if (response) {
        toast(`Silme işlemi başarılı`);
      }
    },
    [currencyService]
  );

  const columns: TableColumnsType<CurrencyModel> = [
    {
      title: "Adı",
      dataIndex: "name",
    },
    {
      title: "Sembol",
      dataIndex: "symbol",
      render(value) {
        const unicodeValue = convertToUnicode(value);
        return <span>{unicodeValue}</span>;
      },
    },
    {
      title: "Text",
      dataIndex: "text",
    },
    {
      width: 50,
      title: "",
      dataIndex: "id",
      render(value) {
        return (
          <Popconfirm
            placement="left"
            title={"Silme işlemini onaylıyormusunuz?"}
            okText="Evet"
            cancelText="Hayır"
            onConfirm={async () => {
              await deleteCurrency(value);
              await getCurrencyList();
            }}
          >
            <Button
              type="text"
              icon={
                <i
                  style={{ color: "red", fontSize: "large" }}
                  className="fa-duotone fa-trash"
                ></i>
              }
            />
          </Popconfirm>
        );
      },
    },
    {
      width: 50,
      title: "",
      dataIndex: "id",
      render(value) {
        return (
          <Button
            type="text"
            onClick={() => {
              id.current = value;
              setOpenModalCurrencyAdd(true);
            }}
            icon={
              <i
                style={{ color: "green", fontSize: "large" }}
                className="fa-duotone fa-pen-to-square"
              ></i>
            }
          />
        );
      },
    },
  ];
  return (
    <div>
      <Title level={3} style={{ color: "#4A5967", textAlign: "center" }}>
        Para Birimi Listesi
      </Title>
      <Button
        onClick={() => {
          id.current = 0;
          setOpenModalCurrencyAdd(true);
        }}
        size="small"
        style={{ margin: 10 }}
        icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
      >
        Yeni Para Birimi Ekle
      </Button>
      <Table columns={columns} dataSource={data} rowKey={(value) => value.id} />
      {openModalCurrencyAdd ? (
        <Modal
          title=""
          open={openModalCurrencyAdd}
          okText={undefined}
          cancelText={undefined}
          onCancel={() => setOpenModalCurrencyAdd(false)}
          styles={{ mask: { backdropFilter: "blur(10px)" } }}
          footer={<></>}
        >
          <AdminCurrencyAdd id={id.current} addCurrency={addCurrency} />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AdminCurrencyList;
