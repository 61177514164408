import React from "react";
import { UserService } from "../../../services/users/user.service";
import { Button, Col, Form, Input, InputNumber, Row, Space } from "antd";
import Title from "antd/es/typography/Title";
import { toast } from "react-toastify";
interface AdminPassowrdResetProps {
  id: number;
  service: UserService;
}
const AdminPassowrdReset: React.FC<AdminPassowrdResetProps> = ({
  id,
  service,
}) => {
  const [form] = Form.useForm();
  const onFinish = async (value: any) => {
    if (id !== 0) {
      let response = await service.passwordReset(value);
      if (response) {
        toast.success("Şifre güncellendi.");
      }
    }
  };
  return (
    <div>
      <Title level={3}>Şifre Yenile</Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row style={{ gap: 3 }}>
          <Form.Item name="id" label="" initialValue={id}>
            <InputNumber style={{ display: "none" }} placeholder="" />
          </Form.Item>

          <Col flex={1}>
            <Form.Item
              name="password"
              label="Yeni şifre"
              rules={[{ required: true }]}
            >
              <Input placeholder="Yeni şifre" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Kaydet
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminPassowrdReset;
