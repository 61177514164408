import { WaiterHubEnum } from "../enums/waiter_hub.enum";
import { HubModel, OrderHubUrl, WaiterHubUrl } from "./api";

export const WaiterHubs = [
  {
    val: (companyId: number): HubModel => {
      return OrderHubUrl(companyId);
    },
    name: WaiterHubEnum.order,
  },
  {
    val: (companyId: number): HubModel => {
      return WaiterHubUrl(companyId);
    },
    name: WaiterHubEnum.waiterCall,
  },
];
