import { Col, Row } from "antd";
import Title from "antd/es/typography/Title";

import React from "react";
import { useSelector } from "react-redux";
import { getCompany } from "../../../react-reduxs/slices/companies/company_slice";
import { ApiUrl } from "../../../consts/api";

const CompanyLogoComponent: React.FC = () => {
  const company = useSelector(getCompany);
  return (
    <div>
      <Row >
        <Col>
          <img
            style={{ borderRadius: 10,margin:"5px 5px 0px 5px" }}
            width={60}
            height={60}
            alt=""
            src={
              company?.logo
                ? ApiUrl + "/" + company?.logo
                : "/images/Backlink.png"
            }
          />
        </Col>
        <Col
          flex={1}
          style={{ maxHeight: "60px", alignItems: "center", display: "flex" }}
        >
          <Title
            style={{
              color: "white",
              marginLeft: 10,
              fontFamily: "BoldGroovy",
              fontWeight: "normal",
            }}
            level={1}
          >
            {company?.title}
          </Title>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyLogoComponent;
