import { Button, Col, Form, Input, InputNumber, Row, Space } from "antd";
import Title from "antd/es/typography/Title";
import React, { useCallback, useEffect, useState } from "react";
import { CurrencyModel } from "../../../services/currencies/models/currency.model";
import { CurrencyService } from "../../../services/currencies/currency.service";

interface AdminCurrencyAddProps {
  addCurrency: (value: CurrencyModel) => void;
  id: number;
}
const AdminCurrencyAdd: React.FC<AdminCurrencyAddProps> = ({
  addCurrency,
  id = 0,
}) => {
  const currencyService = new CurrencyService();
  const [form] = Form.useForm();
  const [count, setCount] = useState(0);
  const onFinish = (values: any) => {
    addCurrency(values);
  };

  const onFinishFailed = () => {};
  const getCurrency = useCallback(async () => {
    let response = await currencyService.getById(id);
    if (response) {
      form.setFieldValue("id", response.id);
      form.setFieldValue("name", response.name);
      form.setFieldValue("symbol", response.symbol);
      form.setFieldValue("text", response.text);
    }
  }, [currencyService, form, id]);
  useEffect(() => {
    if (id !== 0) {
      setCount((cou) => {
        if (cou === 0) {
          getCurrency();
        }
        return 1;
      });
    }

    return () => {};
  }, [getCurrency, id]);

  return (
    <div>
      <Title level={3}>Para Birimi Ekle</Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row style={{ gap: 3 }}>
          <Col flex={1}>
            <Form.Item name="id" label="" initialValue={id} >
              <InputNumber placeholder="" style={{ display: "none" }}  />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item name="name" label="Adı" rules={[{ required: true }]}>
              <Input placeholder="Adı" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              name="symbol"
              label="Sembol"
              rules={[{ required: true }]}
            >
              <Input placeholder="Sembol" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item name="text" label="Text" rules={[{ required: true }]}>
              <Input placeholder="Text" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Kaydet
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminCurrencyAdd;
