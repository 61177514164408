// import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OrderStateModel } from "../../../services/orders/models/order_state.model";
import { RootState } from "../../configure_store";
import { OrderProductModel } from "../../../services/order_products/models/order_product.model";

interface OrderState {
  orderList: OrderStateModel[];
  orderState?: OrderStateModel;
}

const initialState: OrderState = {
  orderList: [],
};
const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<OrderStateModel>) => {
      state.orderState = action.payload;
    },
    orderSetOrderProduct: (
      state,
      action: PayloadAction<OrderProductModel[]>
    ) => {
      let order = state.orderState;
      if (order) {
        order.orderProductList = [...order.orderProductList, ...action.payload];
      }
      state.orderState = order;
    },
  },
});

export const { setOrder, orderSetOrderProduct } = orderSlice.actions;

export const selectOrder = (state: RootState) => state.order.orderState;

const OrderReducer = orderSlice.reducer;
export default OrderReducer;
