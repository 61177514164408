import React from "react";

const CompanyNotFound: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src="/gif/404.gif" alt="" /> <h1 style={{fontFamily:"BoldGroovy"}}>Company Not Found</h1>
      </div>
    </div>
  );
};

export default CompanyNotFound;
