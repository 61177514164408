import i18n from "i18next";
import React from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";
import En from "./languages/en";
import Tr from "./languages/tr";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
   
    resources: {
      en: {
        translation: En,
      },
      tr: {
        translation: Tr,
      },
    },
    lng: "tr", // if you're using a language detector, do not define the lng option
    fallbackLng: "tr",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
interface I18nConfigureProvicerProps {
  children: React.ReactNode;
}
const I18nConfigureProvicer: React.FC<I18nConfigureProvicerProps> = ({
  children = <></>,
}) => {
  return <I18nextProvider  i18n={i18n}>{children} </I18nextProvider>;
};

export default I18nConfigureProvicer;
