import { SelectionItem } from "../../models/selection_item";
import BaseApiService from "../base_api.service";

export class GroupClaimService extends BaseApiService {
  /**
   *
   */
  constructor() {
    super("group-claims");
  }

  async getByGroupId(id: number): Promise<SelectionItem[]> {
    var response = await this.get<SelectionItem[]>(`/groups/${id}`);
    return response ?? [];
  }
  add(
    operationClaimList: number[],
    groupId: number
  ): Promise<string | undefined> {
    return this.post<string>(``, {
      operationClaims: operationClaimList,
      groupId: groupId,
    });
  }
  deleted(claimIds: number[], groupId: number): Promise<string | undefined> {
    console.error(groupId);

    return this.post<string>(`DeleteList`, { claimIds, groupId });
  }
}
