import { AccessTokenModel } from "../../models/access_token.model";
import BaseApiService from "../base_api.service";
import { LoginModel } from "./models/login.model";

export class AuthService extends BaseApiService {
  /**
   *
   */
  constructor() {
    super("Auth");
  }
  async login(loginModel:LoginModel): Promise<AccessTokenModel |undefined> {
   return await this.post<AccessTokenModel>(`/login`,loginModel);
    
  }
}
