import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../react-reduxs/configure_store";
import {
  getToken,
  setCompanyId,
  setToken,
} from "../../react-reduxs/slices/auth/auth_slice";
import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { I18nPagePropertyEnum } from "../../i18n/i18n_page_property";
import i18n from "i18next";
import "../../enums/languages/language.enum";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../services/auth/auth.service";
import { LoginModel } from "../../services/auth/models/login.model";
import { UserTypeEnum } from "../../enums/user_type.enum";
import { CompanyService } from "../../services/companies/company.service";
import { setCompanyPublic } from "../../react-reduxs/slices/companies/company_slice";

const LoginPage = () => {
  const authService = useMemo(() => new AuthService(), []);
  const companyService = useMemo(() => new CompanyService(), []);

  const token = useSelector(getToken);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch: AppDispatch = useDispatch();
  const [languageList, seLanguageList] = useState<string[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const languageChange = useCallback((language: string) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    if (i18n.options.resources) {
      const resourceKeys = Object.keys(i18n.options.resources);
      seLanguageList(resourceKeys);
    }

    return () => {};
  }, []);
  useEffect(() => {
    if (token !== "") {
      const decodedToken: any = JSON.parse(atob(token.split(".")[1]));
      if (decodedToken?.userType === UserTypeEnum.Waiter) {
        navigate("/waiter");
      } else if (decodedToken?.userType === UserTypeEnum.Admin) {
        navigate("/admin");
      } else if (decodedToken?.userType === UserTypeEnum.CompanyManager) {
        console.error(decodedToken);

        if (decodedToken.companyId == 0 || decodedToken.companyId === null) {
          navigate("/company-not-found");
        } else {
          companyService
            .getByCompanyId(decodedToken.companyId)
            .then((response) => {
              if (response) {
                dispatch(setCompanyId(response.id));
                dispatch(setCompanyPublic(response));
                navigate("/compManager");
              }
            });
        }
      }
    }
    return () => {};
  }, [navigate, token]);

  const onFinish = useCallback(
    async (values: LoginModel) => {
      try {
        var response = await authService.login(values);

        if (response?.token) {
          let decodeToken = JSON.parse(atob(response.token.split(".")[1]));
          if (decodeToken) {
            if (decodeToken.companyId) {
              dispatch(setCompanyId(decodeToken.companyId));
            }
          }
          await dispatch(setToken(response.token));
        }
      } catch (error) {
        // Token ayarlanamadı, uygun bir şekilde işlem yapılabilir (örneğin, kullanıcıya bir hata mesajı gösterilebilir)
      }
    },
    [authService, dispatch]
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "rgb(238 254 255 / 80%)",
        }}
      >
        <Card style={{ minWidth: 400 }}>
          <Row style={{ height: 50, marginBottom: 10 }}>
            <Col flex={1}>
              <img width={50} src="./images/Backlink.png" />
            </Col>
            <Col
              style={{
                paddingLeft: 10,
              }}
              flex={2}
            >
              <Title
                level={3}
                style={{
                  maxHeight: 3,
                  display: "flex",
                  alignItems: "center",

                  fontWeight: "normal",
                  fontFamily: "BoldGroovy",
                }}
              >
                Link {t(I18nPagePropertyEnum.loginTitle)}
              </Title>
            </Col>
          </Row>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="userName"
              label={`${t(I18nPagePropertyEnum.loginUserName)} / ${t(
                I18nPagePropertyEnum.loginEmail
              )}`}
              hasFeedback
              rules={[{ required: true }, { type: "string", min: 2 }]}
            >
              <Input
                placeholder={`${t(I18nPagePropertyEnum.loginUserName)} / ${t(
                  I18nPagePropertyEnum.loginEmail
                )}`}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={`${t(I18nPagePropertyEnum.loginPassword)}`}
              hasFeedback
              rules={[{ required: true }, { type: "string", min: 6 }]}
            >
              <Input.Password
                placeholder={`${t(I18nPagePropertyEnum.loginPassword)}`}
              />
            </Form.Item>
            <Form.Item
              label={`${t(I18nPagePropertyEnum.loginLanguage)}`}
              hasFeedback
              rules={[{ required: true }, { type: "string", min: 6 }]}
            >
              <Select
                value={selectedLanguage}
                onChange={(value) => languageChange(value)}
                options={languageList.map((lang) => ({
                  label: lang.toText(),
                  value: lang,
                }))}
              />
            </Form.Item>
            <Form.Item>
              <Button
                icon={<i className="fa-duotone fa-arrow-right-from-arc"></i>}
                style={{ fontWeight: "bold", minWidth: "100%" }}
                htmlType="submit"
              >
                {`${t(I18nPagePropertyEnum.loginLogin)}`}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default LoginPage;
