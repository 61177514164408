import { Button, Col, Form, Input, Row, Space } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { GroupModel } from "../../../services/groups/models/group.model";

interface AdminGroupAddProps {
  groupAdd: (value: GroupModel) => void;
}
const AdminGroupAdd: React.FC<AdminGroupAddProps> = ({ groupAdd }) => {
  const [form] = Form.useForm();
  const onFinish = (values: GroupModel) => {
    groupAdd(values);
  };

  const onFinishFailed = () => {};
  return (
    <div>
      <Title level={3}>Kullanıcı Grubu Ekle</Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row style={{ gap: 3 }}>
          <Col flex={1}>
            <Form.Item
              name="groupName"
              label="Grub Adı"
              rules={[{ required: true }]}
            >
              <Input placeholder="Grub Adı" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Kaydet
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminGroupAdd;
