import React from "react";
import {
  classNames,
  defaultPosition,
} from "./funcs/horizantel_scrollable.func";
import {
  IScrollableComponentProps,
  IScrollableComponentState,
} from "./interfaces/horizantel_scrollable.interface";

const HorizantelScrollable = (props: IScrollableComponentProps) => {
  const ref: React.MutableRefObject<HTMLDivElement | null> =
    React.useRef<HTMLDivElement>(null);

  const [state, setStateTo] = React.useState<IScrollableComponentState>({
    grabbing: false,
    position: defaultPosition(),
  });

  const handleOnMouseDown = (e: any): void => {
    if (ref.current) {
      setStateTo({
        ...state,
        grabbing: true,
        position: {
          x: e.clientX,
          left: ref.current.scrollLeft,
        },
      });
    }
  };

  const handleOnMouseMove = (e: any): void => {
    if (state.grabbing) {
      const left: number = Math.max(
        0,
        state.position.left + (state.position.x - e.clientX)
      );

      if (ref.current) {
        ref.current.scrollLeft = left;
      }
    }
  };

  const handleOnMouseUp = (): void => {
    if (state.grabbing) {
      setStateTo({ ...state, grabbing: false });
    }
  };
  return (
    <div
      ref={ref}
      className={classNames("scrollable-component", props.className)}
      id={props.id}
      style={props.style}
      onMouseDown={handleOnMouseDown}
      onMouseMove={handleOnMouseMove}
      onMouseUp={handleOnMouseUp}
      onMouseLeave={handleOnMouseUp}
    >
      {props.children}
    </div>
  );
};

export default HorizantelScrollable;
