import { Button, Col, List, Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";
import { OrderStateModel } from "../../../../services/orders/models/order_state.model";
import { OrderProductStatusModel } from "../../../../services/order_product_statuses/models/order_product_status.model";
import "../../../../enums/orders/order_status.enum";
import { ApiUrl } from "../../../../consts/api";
const { Text } = Typography;

interface TableOrderListComponentProps {
  tableId?: string;
  orderState?: OrderStateModel;
  orderProductStatusList: OrderProductStatusModel[];
  updateOrderProductStatus: any;
}
const TableOrderListComponent: React.FC<TableOrderListComponentProps> = ({
  tableId,
  orderState,
  orderProductStatusList,
  updateOrderProductStatus,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: (page) => {
            console.log(page);
          },
          pageSize: 4,
        }}
        dataSource={orderState?.orderProductList ?? []}
        renderItem={(item) => (
          <List.Item
            key={item.menuProductName}
            style={{
              display: "block",
              backgroundColor: item.status.id !== 1 ? "#f5feff" : "white",
              padding: 0,
            }}
            actions={orderProductStatusList.map((x) => (
              <Button
                onClick={() => {
                  updateOrderProductStatus(tableId, item.id, x.id);
                }}
                style={{
                  margin: 5,
                  fontSize: 10,
                  color: x.color,
                  borderColor: x.color,
                  backgroundColor:
                    item.status.text === x.text
                      ? "rgb(132 242 234)"
                      : "transparent",
                }}
                size="small"
                icon={<i className="fa-solid fa-check"></i>}
              >
                {t(x.text.orderProductStatusLang())}
              </Button>
            ))}
          >
            <List.Item.Meta
              title={
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Row>
                    <Col style={{ padding: 5 }}>
                      <img
                        style={{ margin: 0, objectFit: "scale-down" }}
                        height={70}
                        alt="logo"
                        src={ApiUrl+"/"+item.menuProductPhoto}
                      />
                    </Col>
                    <Col flex={1}>
                      <Title style={{ margin: 0, fontSize: 15 }} level={5}>
                        {item.menuProductName}
                      </Title>
                      <Title level={5} style={{ color: "red", margin: 0 }}>
                        Adet: {item.amount}
                      </Title>
                      <Text type="secondary">
                        {"Sipariş Notu: " + item.orderNote}
                      </Text>
                    </Col>
                  </Row>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default TableOrderListComponent;
