import React from "react";
import "./ui_card.scss";
interface UICardProps {
  imageSrc: string;
  title: string;
  description?: string;
  price: string;
  Footer?: React.ReactNode;
  Duraiton?: React.ReactNode;
}
const UICard: React.FC<UICardProps> = ({
  imageSrc,
  title,
  price,
  Footer = <></>,
  description,
  Duraiton=<></>
}) => {
  return (
    <div className="nft">
      <div className="main">
        <img className="tokenImage" src={imageSrc} alt="NFT" />
        <h2>{title}</h2>
        <p className="description">{description}</p>
        <div className="tokenInfo">
          <div className="price">
            <ins>
              <i className="fa-duotone fa-tags"></i>
            </ins>
            <p>{price}</p>
          </div>
          <div className="duration">{Duraiton}</div>
        </div>
        <hr />
        <div className="creator">
        {Footer}
        </div>
      </div>
    </div>
  );
};

export default UICard;
