import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { GroupModel } from "../../../services/groups/models/group.model";

const AdminUserAdd: React.FC = () => {
  const [form] = Form.useForm();
  const [groupList, setGroupList] = useState<GroupModel[]>([]);
  const onFinish = () => {};

  const onFinishFailed = () => {};

  return (
    <div>
      <Title level={3}>Kullanıcı Ekle</Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row style={{ gap: 3 }}>
          <Col flex={1}>
            <Form.Item
              name="userName"
              label="Kullanıcı Adı"
              rules={[{ required: true }]}
            >
              <Input placeholder="Kullanıcı Adı" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item name="email" label="E-Posta" rules={[{ required: true }]}>
              <Input placeholder="E-Posta" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item name="groupId" label="Kullanıcı Grubu" rules={[{ required: true }]}>
              <Select
                mode="multiple"
                placeholder="Inserted are removed"
                style={{ width: "100%" }}
                options={groupList.map((item) => ({
                  value: item.id,
                  label: item.groupName,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Kaydet
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminUserAdd;
