import BaseApiService from "../base_api.service";
import { CurrencyModel } from "./models/currency.model";

export class CurrencyService extends BaseApiService {
  /**
   *
   */
  constructor() {
    super("currencies");
  }
  async getList(): Promise<CurrencyModel[]> {
    let response = await this.get<CurrencyModel[]>(``);
    return response ?? [];
  }
  add(currencyModel: CurrencyModel): Promise<CurrencyModel | undefined> {
    return this.post<CurrencyModel>(``, currencyModel);
  }
  update(currencyModel: CurrencyModel): Promise<CurrencyModel | undefined> {
    return this.put<CurrencyModel>(``, currencyModel);
  }
  deleted(id: number): Promise<string | undefined> {
    return this.delete<string>(``,{
      data:{
        id:id
      }
    });
  }
  getById(id: number): Promise<CurrencyModel | undefined> {
    return this.get<CurrencyModel>(`GetById?id=${id}`);
  }
}
