const ApiUrl ="https://apimenu.qrmenuscan.com"//"https://localhost:5001" //"https://apimenu.qrmenuscan.com/";

const OrderHubUrl = (companyId: number): HubModel => {
  return {
    url: `${ApiUrl}/orderHub?companyId=${companyId}`,
    event: "ReceiveOrder",
  };
};
const WaiterHubUrl = (companyId: number): HubModel => {
  return {
    url: `${ApiUrl}/waiterHub?companyId=${companyId}`,
    event: "WaiterCall",
  };
};
export { ApiUrl, OrderHubUrl, WaiterHubUrl };

export interface HubModel {
  url: string;
  event: string;
}
