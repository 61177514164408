import { Dropdown, MenuProps, Space } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import React, { useCallback, useState } from "react";
import './dropdown.css'
interface UiDropdownListProps {
  items: ItemType[];
  onClick: MenuProps["onClick"];
  children: React.ReactNode;
}
const UiDropdownList: React.FC<UiDropdownListProps> = ({
  items = [],
  onClick,
  children = <></>,
}) => {
  const [openVisble, setOpenVisble] = useState(false);
  const menuProps = {
    items,
    onClick,
  };
  const dropdownOpenChange = useCallback(() => {
    if (openVisble) {
      setOpenVisble(false);
    } else {
      setOpenVisble(true);
    }
  }, [openVisble]);

  return (
    <>
      {openVisble ? (
        <div
          onClick={() => dropdownOpenChange()}
          style={{
            position: "fixed",
            inset: 0,
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.45)",
            backdropFilter: " blur(10px)",
            zIndex: "inherit"

          }}
        ></div>
      ) : (
        <></>
      )}
      <Dropdown
        open={openVisble}
        overlayStyle={{ width: "100%", maxWidth: "350px", paddingRight:20 }}
        trigger={["click"]}
        menu={menuProps}
        overlayClassName="mdropdown"
        placement="topLeft"
      >
        <Space
          onClick={() => {
            dropdownOpenChange();
          }}
          style={{ cursor: "pointer", margin: 5, marginBottom: 7 }}
        >
          {children}
        </Space>
      </Dropdown>
    </>
  );
};

export default UiDropdownList;
