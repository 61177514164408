import BaseApiService from "../base_api.service";
import { OperationClaimModel } from "./models/operation_claim.model";

export class OperationClaimService extends BaseApiService {
  /**
   *
   */
  constructor() {
    super("operation-claims");
  }

  async list(): Promise<OperationClaimModel[]> {
    let response = await this.get<OperationClaimModel[]>(``);
    return response ?? [];
  }
}
