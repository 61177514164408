import React from "react";
import { MenuCategoryModel } from "../../../../../services/menu_categories/models/menu_category.model";
import {} from "antd";

interface Template3CategoryListProps {
  categoryList: MenuCategoryModel[];
  selectedCategory: number;
  onClick: (id: number) => void
}
const Template3CategoryList: React.FC<Template3CategoryListProps> = ({
  categoryList,
  selectedCategory,
  onClick
}) => {
  return (
    <div className="buttons-container">
      <a onClick={()=>onClick(0)}
        className={
          selectedCategory === 0 ? "button button--is-active" : "button "
        }
      >
        Tüm Ürünler
      </a>
      {categoryList.flatMap((x) => (
        <a key={x.id} onClick={()=>onClick(x.id)}
          className={
            selectedCategory === x.id ? "button button--is-active" : "button "
          }
        >
          {x.name}
        </a>
      ))}
    </div>
  );
};

export default Template3CategoryList;
