import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Menu, Row, theme } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import UserProfileComponent from "../../components/UserProfileComponent";

const CompanyManagerLayout: React.FC = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout style={{ height: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <Row style={{ margin: 4 }}>
          <Col>
            <img height={30} src="/images/Backlink.png" alt="" />
          </Col>
          <Col>
            <Title
              level={4}
              style={{
                margin: 0,
                padding: 0,
                color: "white",
                marginLeft: 10,
                fontFamily: "MayfordDisplayRegular",
              }}
            >
              Link
            </Title>
          </Col>
        </Row>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={[
            {
              key: "1",
              icon: <i className="fa-duotone fa-house"></i>,
              label: "Anasayfa",
              onClick: () => {
                navigate("/compManager/");
              },
            },
            {
              key: "2",
              icon: <i className="fa-duotone fa-rectangle-history-circle-user"></i>,
              label: "Kullanıcı List",
              onClick: () => {
                navigate("/compManager/company-user-list");
              },
            },
            {
              key: "3",
              icon: <i className="fa-duotone fa-tablet-rugged"></i>,
              label: "Masa Listesi",
              onClick: () => {
                navigate("/compManager/table-list");
              },
            },
            {
              key: "4",
              icon: <i className="fa-brands fa-product-hunt"></i>,
              label: "Ürün Listesi",
              onClick: () => {
                navigate("/compManager/menu-product-list");
              },
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Row>
            <Col flex={1}>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
            </Col>
            <Col style={{ display: "flex", alignItems: "center" }}>
              <UserProfileComponent />
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default CompanyManagerLayout;
