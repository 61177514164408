import { Button, Col, Form, Input, Row, Space } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { SectionModel } from "../../../services/sections/models/section.model";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import FileUploadComponent from "../../tests/FileUploadComponent";
import { getCompany } from "../../../react-reduxs/slices/companies/company_slice";

interface CompManagerSectionAddProps {
  id?: number;
  addSection: (value: SectionModel) => void;
}

const CompManagerSectionAdd: React.FC<CompManagerSectionAddProps> = ({
  addSection,
  id,
}) => {
  const companyId = useSelector(getCompanyId);
  const company = useSelector(getCompany);
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    if (companyId) {
      addSection({
        compnayId: companyId,
        name: values.name,
        id: 0,
        logo: imageUrl === "" ? undefined : imageUrl,
      });
    }
  };
  const [imageUrl, setImageUrl] = useState("");
  return (
    <div>
      <Title level={3}>Bölüm Ekle</Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row style={{ gap: 3 }}>
          <Col flex={1}>
            <Form.Item name="name" label="Adı" rules={[{ required: true }]}>
              <Input placeholder="Adı" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <FileUploadComponent
              path={"Companies/" + company?.folderName + "/Sections"}
              setImageUrlParam={setImageUrl}
            />
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Kaydet
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CompManagerSectionAdd;
