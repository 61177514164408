import { I18nPagePropertyEnum } from "../../i18n/i18n_page_property";

export enum OrderStatusEnum {
  /**
   * Ödeme yapılmadı
   */
  paymentNotMade = "Payment not made",
  paymentCompleted = "Payment completed",
}
export enum OrderProductStatusEnum {
  /**
   * Onay bekleyen
   */
  pendingApproval = "Pending Approval",
  /**
   * Onaylandı
   */
  approved = "Approved",
  /**
   * İptal edildi
   */
  itIsCancelled = "It is cancelled",
  /**
   * Sipariş hazırlanıyor
   */
  orderIsBeingPrepared = "Order is being prepared",
}
declare global {
  interface String {
    orderProductStatusLang(): string;
    orderStatusLang(): string;
  }
}

// eslint-disable-next-line no-extend-native
String.prototype.orderProductStatusLang = function (this: string): string {
  switch (this) {
    case OrderProductStatusEnum.pendingApproval:
      return I18nPagePropertyEnum.commonPendingApproval;
    case OrderProductStatusEnum.approved:
      return I18nPagePropertyEnum.commonApproved;
    case OrderProductStatusEnum.itIsCancelled:
      return I18nPagePropertyEnum.commonItIsCancelled;
    case OrderProductStatusEnum.orderIsBeingPrepared:
      return I18nPagePropertyEnum.commonOrderIsBeingPrepared;
    default:
      return "";
  }
};
// eslint-disable-next-line no-extend-native
String.prototype.orderStatusLang = function (this: string): string {
  switch (this) {
    case OrderStatusEnum.paymentNotMade:
      return I18nPagePropertyEnum.commonPaymentNotMade;
    case OrderStatusEnum.paymentCompleted:
      return I18nPagePropertyEnum.commonPaymentCompleted;
    default:
      return "";
  }
};
