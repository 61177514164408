import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../configure_store";

interface TableState {
  tableId: string;
}

const initialState: TableState = {
  tableId: "",
};
const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setTableId: (state, action: PayloadAction<string>) => {
      state.tableId = action.payload;
    },
  },
});

export const { setTableId } = tableSlice.actions;

export const getTableId = (state: RootState) => state.table.tableId;

const TableReducer = tableSlice.reducer;
export default TableReducer;
