import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Layout, Menu, Button, theme, Col, Row } from "antd";
import Sider from "antd/es/layout/Sider";
import { Header, Content } from "antd/es/layout/layout";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import UserProfileComponent from "../../components/UserProfileComponent";
import Title from "antd/es/typography/Title";

const AdminLayout: React.FC = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout style={{ height: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <Row style={{margin:10}}>
          <Col>
            <img height={30} src="/images/Backlink.png" alt="" />
          </Col>
          <Col>
            <Title level={4} style={{margin:0,padding:0,color:"white",marginLeft:10,fontFamily:"MayfordDisplayRegular"}}>Link</Title>
          </Col>
        </Row>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={[
            {
              key: "1",
              icon: <i className="fa-duotone fa-house"></i>,
              label: "Anasayfa",
              onClick: () => {
                navigate("/admin/");
              },
            },
            {
              key: "2",
              icon: <i className="fa-duotone fa-building"></i>,
              label: "Firma Listesi",
              onClick: () => {
                navigate("/admin/companyList");
              },
            },
            {
              key: "3",
              icon: <i className="fa-duotone fa-users"></i>,
              label: "Kullanıcı Listesi",
              onClick: () => {
                navigate("/admin/user-list");
              },
            },
            {
              key: "4",
              icon: <i className="fa-duotone fa-users"></i>,
              label: "Grup Listesi",
              onClick: () => {
                navigate("/admin/group-list");
              },
            },
            {
              key: "5",
              icon: <i className="fa-duotone fa-coins"></i>,
              label: "Para Birimi Listesi",
              onClick: () => {
                navigate("/admin/currency-list");
              },
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Row>
            <Col flex={1}>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
            </Col>
            <Col style={{ display: "flex", alignItems: "center" }}>
              <UserProfileComponent />
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
