import React from "react";
import { createSignalRContext } from "react-signalr";
interface SignalRContextProps {
  children: React.ReactNode;
}

const Context = createSignalRContext();

const SignalRContext: React.FC<SignalRContextProps> = ({ children }) => {
  const token = "";
  return (
    <Context.Provider
      connectEnabled={!!token}
      accessTokenFactory={() => token}
      dependencies={[token]} //remove previous connection and create a new connection if changed
      url={"https://localhost:7100/hub"}
    >
      {children}
    </Context.Provider>
  );
};

export default SignalRContext;
