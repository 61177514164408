import React, { useState } from "react";
import { MenuCategoryModel } from "../../../services/menu_categories/models/menu_category.model";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { Button, Col, Form, Input, Row, Space } from "antd";
import Title from "antd/es/typography/Title";
import FileUploadComponent from "../../tests/FileUploadComponent";
import { getCompany } from "../../../react-reduxs/slices/companies/company_slice";

interface CompManagerMenuCategoryAddProps {
  modalClose?: () => void;
  saveCategory: (value: MenuCategoryModel) => void;
}

const CompManagerMenuCategoryAdd: React.FC<CompManagerMenuCategoryAddProps> = ({
  saveCategory,
  modalClose = () => {},
}) => {
  const company = useSelector(getCompany);
  const companyId = useSelector(getCompanyId);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    if (companyId !== 0) {
      await saveCategory({
        companyId: companyId,
        topCategoryId: 0,
        id: 0,
        ...values,
        photo: imageUrl === "" ? undefined : imageUrl,
      });
      modalClose();
    }
  };

  const [imageUrl, setImageUrl] = useState("");

  return (
    <div>
      <Title level={3}>Kategori Ekle</Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row style={{ gap: 3 }}>
          <Col flex={1}>
            <Form.Item name="name" label="Adı" rules={[{ required: true }]}>
              <Input placeholder="Adı" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <FileUploadComponent
              path={"Companies/" + company?.folderName + "/Categories"}
              setImageUrlParam={setImageUrl}
              setDeleteImageParam={setDeleteStatus}
            />
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Kaydet
            </Button>
          </Space>
        </Form.Item>
      </Form>
      <Button
        disabled={deleteStatus}
        danger
        onClick={() => {
          modalClose();
        }}
      >
        İptal
      </Button>
    </div>
  );
};

export default CompManagerMenuCategoryAdd;
