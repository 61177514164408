import BaseApiService from "../base_api.service";
import { OrderProductModel } from "./models/order_product.model";

export class OrderProductService extends BaseApiService {
  /**
   *
   */
  constructor() {
    super("OrderProducts");
  }

  async updateWaiter(
    orderProductId: number,
    orderProductStatusId: number
  ): Promise<OrderProductModel[]> {
    return await this.put(``, {
      orderProductId: orderProductId,
      orderProductStatusId: orderProductStatusId,
    })??[];
  }
}
