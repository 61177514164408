import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { MenuProductModel } from "../../../services/menu_products/models/menu_product.model";
import { Button, Modal, Table, TableColumnsType } from "antd";
import Title from "antd/es/typography/Title";
import MenuProductServcie from "../../../services/menu_products/menu_product.service";
import dayjs from "dayjs";
import CompManagerMenuProductAdd from "./CompManagerMenuProductAdd";
import MenuCategoryService from "../../../services/menu_categories/menu_category.service";
import { MenuCategoryModel } from "../../../services/menu_categories/models/menu_category.model";
import { toast } from "react-toastify";
import { ApiUrl } from "../../../consts/api";
import { FileUploadService } from "../../../services/file_uploads/file_upload.service";

const CompManagerMenuProductList: React.FC = () => {
  const id = useRef(0);
  const [modalVisible, setModalVisible] = useState(false);
  const companyId = useSelector(getCompanyId);
  const menuProductService = useMemo(() => new MenuProductServcie(), []);
  const menuCategoryService = useMemo(() => new MenuCategoryService(), []);
  const fileUploadService = useMemo(() => new FileUploadService(), []);

  const [count, setCount] = useState(0);
  const [data, setData] = useState<MenuProductModel[]>([]);
  const [categoryList, setCategoryList] = useState<MenuCategoryModel[]>([]);

  const getCategory = useCallback(async () => {
    let response = await menuCategoryService.getList(companyId);

    if (response) {
      setCategoryList(response);
    }
  }, [companyId, menuCategoryService]);

  const getProduct = useCallback(async () => {
    let response = await menuProductService.getList(companyId);
    if (response) {
      setData(response);
    }
  }, [companyId, menuProductService]);

  const initLoad = useCallback(async () => {
    await getCategory();
    await getProduct();
  }, [getCategory, getProduct]);

  useEffect(() => {
    if (companyId) {
      setCount((cou) => {
        if (cou === 0) {
          initLoad();
        }
        return 1;
      });
    }
    return () => {};
  }, [companyId, initLoad]);

  const saveProduct = useCallback(
    async (menuProductModel: MenuProductModel) => {
console.error(menuProductModel);

      if (menuProductModel.id === 0 || menuProductModel.id ===undefined) {
        let response = await menuProductService.add(menuProductModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getProduct();
        }
      } else {
        let response = await menuProductService.update(menuProductModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getProduct();
        }
      }
    },
    [getProduct, menuProductService]
  );
  const deleteMenuProductImage = useCallback(
    async (menuProductModel: MenuProductModel, imagePath: string) => {
      let fileResponse = await fileUploadService.deleteFile(imagePath);
      if (fileResponse) {
        let response = await menuProductService.update({
          ...menuProductModel,
          photo: undefined,
        });
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getProduct();
          return response;
        }
      }
      return undefined;
    },
    [fileUploadService, getProduct, menuProductService]
  );
  const saveCategory = useCallback(
    async (menuCategoryModel: MenuCategoryModel) => {
      let response = await menuCategoryService.add(menuCategoryModel);
      if (response) {
        toast("Kayıt işlemi başarılı.");

        await getCategory();
      }
    },
    [getCategory, menuCategoryService]
  );

  const columns: TableColumnsType<MenuProductModel> = [
    {
      width: 100,
      title: "Fotoğraf",
      dataIndex: "photo",
      render: (value, record, index) => {
        return (
          <img
            width={70}
            height={70}
            src={ApiUrl + "/" + value ?? "/images/product.png"}
            alt=""
          />
        );
      },
    },
    {
      title: "Başlık",
      dataIndex: "title",
    },
    {
      title: "Fiyatı",
      dataIndex: "price",
    },
    {
      title: "Detay",
      dataIndex: "detail",
    },
    {
      title: "Ekleme Zamanı",
      dataIndex: "createdDate",
      render: (value) => {
        return <div>{dayjs(value).format("DD/MM/YYYY HH:mm")}</div>;
      },
    },
    {
      title: "Güncellenme Zamanı",
      dataIndex: "updatedDate",
    },
    {
      width:50,
      title: "",
      dataIndex: "id",
      render: (value) => {
        return (
          <Button
            type="text"
            onClick={() => {
              id.current = value;
              setModalVisible(true);
            }}
            style={{ backgroundColor: "#C4F3F7B2" }}
            icon={
              <i
                style={{ color: "green", fontSize: "large" }}
                className="fa-duotone fa-pen-to-square"
              ></i>
            }
          />
        );
      },
    },
  ];
  return (
    <div>
      <Title level={3} style={{ color: "#4A5967", textAlign: "center" }}>
        Ürün Listesi
      </Title>
      <Button
        onClick={() => {
          id.current = 0;
          setModalVisible(true);
        }}
        size="small"
        style={{ margin: 10 }}
        icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
      >
        Yeni Ürün Ekle
      </Button>
      <Table
        columns={columns}
        dataSource={data}
        rowKey={"id"}
        pagination={{ pageSize: 10 }}
        scroll={{ y: 300 }}
      />
      {modalVisible ? (
        <Modal footer={<></>} open={modalVisible}>
          <CompManagerMenuProductAdd
            deleteMenuProductImage={deleteMenuProductImage}
            categoryList={categoryList}
            closeModal={() => setModalVisible(false)}
            saveProduct={saveProduct}
            saveCategory={saveCategory}
            productService={menuProductService}
            id={id.current}
          />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompManagerMenuProductList;
