import { Button, Checkbox, Modal, Table, TableColumnsType } from "antd";
import Title from "antd/es/typography/Title";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import AdminUserAdd from "./AdminUserAdd";
import { UserService } from "../../../services/users/user.service";
import { UserListModel } from "../../../services/users/models/user_list.model";
import dayjs from "dayjs";
import AdminPassowrdReset from "./AdminPassowrdReset";

const AdminUserList = () => {
  const [openModalUserAdd, setOpenModalUserAdd] = useState<boolean>(false);
  const [data, setData] = useState<UserListModel[]>([]);
  const [count, setCount] = useState(0);

  const userService = useMemo(() => new UserService(), []);

  const getUserList = useCallback(async () => {
    let response = await userService.list();
    if (response) {
      setData(response);

      console.error(response);
      
    }
  }, [userService]);

  const initLoad = useCallback(async () => {
    await getUserList();
  }, [getUserList]);

  useEffect(() => {
    setCount((cou) => {
      if (cou === 0) {
        initLoad();
      }
      return 1;
    });

    return () => {};
  }, [initLoad]);

  const columns: TableColumnsType<UserListModel> = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Kullanıcı Adı",
      dataIndex: "userName",
    },
    {
      title: "E-Posta",
      dataIndex: "email",
    },
    {
      title: "Kullanıcı Grubu",
      dataIndex: "groupName",
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdDate",
      render: (value) => {
        return <>{dayjs(value).format("DD/MM/YYYY HH:mm")}</>;
      },
    },
    {
      title: "Güncelleme Tarihi",
      dataIndex: "updatedDate",
      render: (value) => {
        return <>{dayjs(value).format("DD/MM/YYYY HH:mm")}</>;
      },
    },
    {
      width: 50,
      title: "Kullanıcı Durumu",
      dataIndex: "id",
      render: (value, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              onClick={async () => {
                if (record.deletedDate === undefined) {
                  await userService.disableUser(value, dayjs().format());
                  await getUserList();
                } else {
                  await userService.disableUser(value, null);
                  await getUserList();
                }
              }}
              checked={record.deletedDate === undefined}
            />
          </div>
        );
      },
    },
    {
      width: 50,
      title: "Şifre Yenile",
      dataIndex: "id",
      render: (value) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => {
                Modal.info({
                  okText: "iptal",
                  okType: "danger",
                  content: (
                    <AdminPassowrdReset id={value} service={userService} />
                  ),
                });
              }}
              style={{ backgroundColor: "#C4F3F7B2" }}
              icon={<i className="fa-duotone fa-key"></i>}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Title level={3} style={{ color: "#4A5967", textAlign: "center" }}>
        Kullanıcı Listesi
      </Title>
      {/* <Button
        onClick={() => {
          setOpenModalUserAdd(true);
        }}
        size="small"
        style={{ margin: 10 }}
        icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
      >
        Yeni Kullanıcı Ekle
      </Button> */}
      <Table columns={columns} dataSource={data} rowKey={(value) => value.id} />
      <Modal
        title=""
        open={openModalUserAdd}
        okText={undefined}
        cancelText={undefined}
        onCancel={() => setOpenModalUserAdd(false)}
        styles={{ mask: { backdropFilter: "blur(10px)" } }}
        footer={<></>}
      >
        <AdminUserAdd />
      </Modal>
    </div>
  );
};

export default AdminUserList;
