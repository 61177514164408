import { INumberUtility, IPosition } from "../interfaces/horizantel_scrollable.interface";

const defaultPosition = (): IPosition => ({
  left: 0,
  x: 0,
});
function classNames(...args: (string | undefined | null | false)[]): string {
  return args.filter(Boolean).join(" ");
}
const N: INumberUtility = {
  clamp: (min: number, value: number, max: number) =>
    Math.min(Math.max(min, value), max),
  rand: (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1) + min),
};
export  {defaultPosition,classNames,N}