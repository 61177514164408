import React from "react";
import { MenuProductModel } from "../../../../../services/menu_products/models/menu_product.model";
import { useSelector } from "react-redux";
import { getCompany } from "../../../../../react-reduxs/slices/companies/company_slice";
import { convertToUnicode } from "../../../../../funcs/func";
interface Template3ProductListProps {
  productList: MenuProductModel[];
}
const Template3ProductList: React.FC<Template3ProductListProps> = ({
  productList,
}) => {
  const company = useSelector(getCompany);
  return (
    <div>
      <div className="menu menu--is-visible" >
        {productList.flatMap((x) => (
          <div key={x.id} className="item">
            <div className="item__header" >
              <h3 className="item__title">{x.title}</h3>
              <span className="item__dots"></span>
              <span className="item__price">
                {x.price + " "}
                {convertToUnicode(company?.currency?.symbol ?? "")}
              </span>
            </div>
            <p className="item__description">
              {/* <Image style={{height:100}} src={x.photo}/> */}
              {x.detail}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Template3ProductList;
