import React, { useCallback, useState } from "react";
import { MenuProductAmountType } from "../../models/menu_product_amount_type";
import { MenuProductModel } from "../../services/menu_products/models/menu_product.model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../react-reduxs/configure_store";
import { addBasket } from "../../react-reduxs/slices/baskets/basker-slice";
import { BasketModel } from "../../models/baskets/basket.model";
import { Button, Input, Popconfirm, message } from "antd";
import { I18nPagePropertyEnum } from "../../i18n/i18n_page_property";
import { useTranslation } from "react-i18next";
import { getCompany } from "../../react-reduxs/slices/companies/company_slice";

interface MenuProductBasketAddComponentProps {
  menuProductAmountList: MenuProductAmountType[];
  menuProduct: MenuProductModel;
  tableId: string;
  removeMenuProductAmount: any;
}
const MenuProductBasketAddComponent: React.FC<
  MenuProductBasketAddComponentProps
> = ({
  tableId,
  menuProduct,
  menuProductAmountList,
  removeMenuProductAmount,
}) => {
  const company = useSelector(getCompany);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [orderNote, setOrderNote] = useState("");
  const [basketPopConfirmVisible, setBasketPopConfirmVisible] =
    useState<boolean>(false);

  const success = useCallback(
    (value: number) => {
      messageApi.open({
        type: "success",
        content: `${value} Ürün Sepete Eklendi. 😎`,
      });
    },
    [messageApi]
  );

  const basketAdd = useCallback(
    (menuProduct: MenuProductModel) => {
      let amount =
        menuProductAmountList.find((x) => x.menuProductId === menuProduct.id)
          ?.amount ?? 1;

      dispatch(
        addBasket({
          id: 0,
          tableId: tableId,
          menuProductId: menuProduct.id,
          menuProductName: menuProduct.title,
          menuProductPhoto: menuProduct.photo,
          price: menuProduct.price,
          amount: amount,
          orderNote: orderNote,
        } as BasketModel)
      );
      success(amount);
      removeMenuProductAmount(menuProduct.id);
    },
    [
      menuProductAmountList,
      dispatch,
      tableId,
      orderNote,
      success,
      removeMenuProductAmount,
    ]
  );
  return (
    <div>
      {contextHolder}
      {company?.orderAtTheTable ? (
        <Button
          type="default"
          icon={<i style={{ color: "white" }} className="fal fa-cart-plus"></i>}
          size={"small"}
          style={{
            right: 1,
            fontSize: "smaller",
            backgroundColor: "#6E6E6E00",
            color: "#FFFFFFB0",
            borderColor: "#FFFFFFB0",
          }}
          onClick={() => setBasketPopConfirmVisible(true)}
        >
          {t(I18nPagePropertyEnum.menuProductAddToBasket)}
        </Button>
      ) : (
        <></>
      )}
      <Popconfirm
        cancelText={t(I18nPagePropertyEnum.commonCancel)}
        okText={t(I18nPagePropertyEnum.commonOk)}
        onCancel={() => {
          setBasketPopConfirmVisible(false);
          setOrderNote("");
        }}
        icon={
          <i
            style={{ color: "green", marginRight: 5 }}
            className="fa-duotone fa-cart-plus"
          ></i>
        }
        title={t(I18nPagePropertyEnum.menuProductAddToBasket)}
        description={
          <>
            <label>{t(I18nPagePropertyEnum.menuProductOrderNote)}</label>
            <Input.TextArea
              value={orderNote}
              onChange={(e) => setOrderNote(e.target.value)}
            />
          </>
        }
        open={basketPopConfirmVisible}
        onConfirm={async () => {
          basketAdd(menuProduct);
          setBasketPopConfirmVisible(false);
        }}
      ></Popconfirm>
    </div>
  );
};

export default MenuProductBasketAddComponent;
