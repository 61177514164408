import { Card, Collapse, Image } from "antd";
import Meta from "antd/es/card/Meta";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./ant.css";
import CategoryComponent from "./CategoryComponent";

import CounterComponent from "../../theme/ui-components/counter/CounterComponent";
import { MenuCategoryModel } from "../../services/menu_categories/models/menu_category.model";
import { MenuProductModel } from "../../services/menu_products/models/menu_product.model";
import { useSelector } from "react-redux";

import Title from "antd/es/typography/Title";
import { getTableId } from "../../react-reduxs/slices/tables/tableSlice";
import { MenuProductAmountType } from "../../models/menu_product_amount_type";
import MenuProductOrderButtonComponent from "../menu_products/MenuProductOrderButtonComponent";
import MenuProductBasketAddComponent from "../menu_products/MenuProductBasketAddComponent";
import MenuCategoryService from "../../services/menu_categories/menu_category.service";
import { getCompanyId } from "../../react-reduxs/slices/auth/auth_slice";
import MenuProductServcie from "../../services/menu_products/menu_product.service";
import { getCompany } from "../../react-reduxs/slices/companies/company_slice";
import UICard from "../../theme/ui-components/cards/UICard";
import { ApiUrl } from "../../consts/api";
import { convertToUnicode } from "../../funcs/func";

interface IMenuSectionProps {
  children: any;
  icon: string;
  id: string;
  scrollable?: boolean;
  title: string;
}

interface IMenuSectionProps {
  children: any;
  icon: string;
  id: string;
  scrollable?: boolean;
  title: string;
}
interface IScrollableComponentProps {
  children: any;
  className?: string;
  id?: string;
}
interface IScrollableComponentState {
  grabbing: boolean;
  position: IPosition;
}
interface IPosition {
  left: number;
  x: number;
}
const defaultPosition = (): IPosition => ({
  left: 0,
  x: 0,
});
const ScrollableComponent: React.FC<IScrollableComponentProps> = (
  props: IScrollableComponentProps
) => {
  const ref: React.MutableRefObject<HTMLDivElement | null> =
    React.useRef<HTMLDivElement>(null);

  const [state, setStateTo] = React.useState<IScrollableComponentState>({
    grabbing: false,
    position: defaultPosition(),
  });

  const handleOnMouseDown = (e: any): void => {
    if (ref.current) {
      setStateTo({
        ...state,
        grabbing: true,
        position: {
          x: e.clientX,
          left: ref.current.scrollLeft,
        },
      });
    }
  };

  const handleOnMouseMove = (e: any): void => {
    if (state.grabbing) {
      const left: number = Math.max(
        0,
        state.position.left + (state.position.x - e.clientX)
      );

      if (ref.current) {
        ref.current.scrollLeft = left;
      }
    }
  };

  const handleOnMouseUp = (): void => {
    if (state.grabbing) {
      setStateTo({ ...state, grabbing: false });
    }
  };

  return (
    <div
      ref={ref}
      className={classNames("scrollable-component", props.className)}
      id={props.id}
      onMouseDown={handleOnMouseDown}
      onMouseMove={handleOnMouseMove}
      onMouseUp={handleOnMouseUp}
      onMouseLeave={handleOnMouseUp}
    >
      {props.children}
    </div>
  );
};
function classNames(...args: (string | undefined | null | false)[]): string {
  return args.filter(Boolean).join(" ");
}

const MenuSection: React.FC<IMenuSectionProps> = (props: IMenuSectionProps) => {
  const getContent = (): JSX.Element => {
    if (props.scrollable) {
      return (
        <ScrollableComponent className="menu-section-content">
          {props.children}
        </ScrollableComponent>
      );
    }

    return <div className="menu-section-content">{props.children}</div>;
  };

  return (
    <div id={props.id} className="menu-section">
      <div className="menu-section-title">
        <i className={props.icon} />
        <span className="menu-section-title-text">{props.title}</span>
      </div>
      {getContent()}
    </div>
  );
};

const Restaurants: React.FC<RestaurantProp> = ({ menuProducts = [] }) => {
  const tableId = useSelector(getTableId);
  const company = useSelector(getCompany);
  const [menuProductAmountListState, setMenuProductAmountListState] = useState<
    MenuProductAmountType[]
  >([]);

  const increment = useCallback(
    (menuProductId: number) => {
      setMenuProductAmountListState((prevMenuProductAmountList) => {
        const newMenuProductAmountList = prevMenuProductAmountList.map(
          (element) => {
            if (element.menuProductId === menuProductId) {
              return {
                ...element,
                amount: element.amount + 1,
              };
            }
            return element;
          }
        );
        // Eğer menuProductId listemizde yoksa, yeni bir öğe ekleyin
        if (
          !newMenuProductAmountList.some(
            (element) => element.menuProductId === menuProductId
          )
        ) {
          newMenuProductAmountList.push({
            menuProductId: menuProductId,
            amount: 2,
          });
        }
        return newMenuProductAmountList;
      });
    },
    [setMenuProductAmountListState]
  );
  const descrement = useCallback(
    (menuProductId: number) => {
      setMenuProductAmountListState((prevMenuProductAmountList) => {
        const newMenuProductAmountList = prevMenuProductAmountList.map(
          (element) => {
            if (element.menuProductId === menuProductId) {
              if (element.amount > 1) {
                return {
                  ...element,
                  amount: element.amount - 1,
                };
              }
            }
            return element;
          }
        );
        // Eğer menuProductId listemizde yoksa, yeni bir öğe ekleyin
        if (
          !newMenuProductAmountList.some(
            (element) => element.menuProductId === menuProductId
          )
        ) {
          newMenuProductAmountList.push({
            menuProductId: menuProductId,
            amount: 2,
          });
        }
        return newMenuProductAmountList;
      });
    },
    [setMenuProductAmountListState]
  );
  const removeMenuProductAmount = useCallback(
    (menuProductId: number) => {
      let newList = menuProductAmountListState.filter(
        (x) => x.menuProductId !== menuProductId
      );
      setMenuProductAmountListState(newList);
    },
    [menuProductAmountListState, setMenuProductAmountListState]
  );

  const GetRestaurants: React.FC = () => {
    return (
      <>
        {menuProducts.map((restaurant, i) => {
          let count =
            menuProductAmountListState.find(
              (x) => x.menuProductId === restaurant.id
            )?.amount ?? 1;
          return (
            <UICard
              key={restaurant.id}
              imageSrc={ApiUrl + "/" + restaurant.photo}
              title={restaurant.title}
              price={
                (count * restaurant.price).toFixed(2) +
                " " +
                convertToUnicode(company?.currency?.symbol??"")
              }
              description={restaurant.detail}
              Duraiton={
                <CounterComponent
                  count={count}
                  increment={() => increment(restaurant.id)}
                  descrement={() => descrement(restaurant.id)}
                />
              }
              Footer={
                <div style={{ display: "flex", gap: 5 }}>
                  <MenuProductBasketAddComponent
                    menuProduct={restaurant}
                    menuProductAmountList={menuProductAmountListState}
                    removeMenuProductAmount={removeMenuProductAmount}
                    tableId={tableId}
                  />
                  <MenuProductOrderButtonComponent
                    menuProduct={restaurant}
                    removeMenuProductAmount={removeMenuProductAmount}
                    menuProductAmountList={menuProductAmountListState}
                    tableId={tableId}
                  />
                </div>
              }
            />
            // <div className="product-card" key={restaurant.id}>
            //   <Card
            //     style={{}}
            //     styles={{
            //       body: {
            //         padding: 0,
            //         minHeight: company?.orderAtTheTable ? 170 : 100,
            //       },
            //     }}
            //     className="car-product product-card"
            //     cover={
            //       <>
            //         <MenuProductBasketAddComponent
            //           menuProduct={restaurant}
            //           menuProductAmountList={menuProductAmountListState}
            //           removeMenuProductAmount={removeMenuProductAmount}
            //           tableId={tableId}
            //         />
            //         <Image
            //           style={{
            //             maxHeight: company?.orderAtTheTable ? 150 : 200,
            //             minHeight: company?.orderAtTheTable ? 150 : 200,
            //             width: "100%",
            //             objectFit: "cover",
            //           }}
            //           alt="example"
            //           src={restaurant.photo}
            //         />
            //       </>
            //     }
            //   >
            //     <Collapse
            //       ghost
            //       accordion
            //       onChange={(e) => {
            //         if (
            //           e.length > 0 &&
            //           restaurant.detail !== undefined &&
            //           restaurant.detail.length > 0
            //         ) {
            //           setCollapseVisibleList([
            //             ...collapseVisibleList,
            //             { index: i },
            //           ]);
            //         } else {
            //           setCollapseVisibleList(
            //             collapseVisibleList.filter((x) => x.index !== i) ?? []
            //           );
            //         }
            //       }}
            //       items={[
            //         {
            //           key: "1",
            //           label: (
            //             <Title
            //               style={{
            //                 fontWeight: "bold",
            //                 margin: 0,
            //                 fontSize: "smaller",
            //                 maxHeight: 54,
            //                 overflowY: "auto",
            //               }}
            //               level={5}
            //             >
            //               {restaurant.title}
            //             </Title>
            //           ),
            //           children: (
            //             <p
            //               style={{
            //                 margin: 0,
            //                 fontSize: "smaller",
            //                 maxHeight: 70,
            //                 overflowY: "auto",
            //               }}
            //             >
            //               {restaurant.detail}
            //             </p>
            //           ),
            //         },
            //       ]}
            //     />
            //     {collapseVisibleList.findIndex((z) => z.index === i) === -1 ? (
            //       <>
            //         <Meta
            //           style={{
            //             paddingBottom: 5,
            //             fontFamily: "RegaveDemibold",
            //             boxShadow: "0 0 1px #6D6D6D",
            //             borderRadius: 5,
            //             textAlign: "center",
            //             margin: 5,
            //             padding: 5,
            //           }}
            //           title={
            //             <div
            //               style={{
            //                 display: "flex",
            //                 flexDirection: "column",
            //                 justifyContent: "center",
            //                 alignItems: "center",
            //                 gap: 5,
            //                 fontSize: "smaller",
            //               }}
            //             >
            //               {count * restaurant.price +" " +(company?.currency?.symbol??"")}
            //               {company?.orderAtTheTable ? (
            //                 <CounterComponent
            //                   count={count}
            //                   increment={() => increment(restaurant.id)}
            //                   descrement={() => descrement(restaurant.id)}
            //                 />
            //               ) : (
            //                 <></>
            //               )}
            //             </div>
            //           }
            //         />
            //         {company?.orderAtTheTable ? (
            //           <MenuProductOrderButtonComponent
            //             menuProduct={restaurant}
            //             removeMenuProductAmount={removeMenuProductAmount}
            //             menuProductAmountList={menuProductAmountListState}
            //             tableId={tableId}
            //           />
            //         ) : (
            //           <></>
            //         )}
            //       </>
            //     ) : (
            //       <></>
            //     )}
            //   </Card>
            // </div>
          );
        })}
      </>
    );
  };
  return (
    <MenuSection icon="" id="restaurants-section" title="">
      <GetRestaurants />
    </MenuSection>
  );
};

const ProductTabs: React.FC<ProductTabsPorp> = () => {
  const categoryService = useMemo(() => new MenuCategoryService(), []);
  const productService = useMemo(() => new MenuProductServcie(), []);
  const companyId = useSelector(getCompanyId);
  const [count, setCount] = useState<number>(0);
  const menuProductListRef = useRef<MenuProductModel[]>([]);
  const [categoryList, setCategoryList] = useState<MenuCategoryModel[]>([]);
  const [menuProductList, setMenuProductList] = useState<MenuProductModel[]>(
    []
  );
  const initLoad = useCallback(async () => {
    let response = await categoryService.getList(companyId);
    let productResponse = await productService.getList(companyId);
    if (response) {
      setCategoryList(response);
    } else {
      setCategoryList([]);
    }
    if (productResponse) {
      menuProductListRef.current = productResponse;
      setMenuProductList(productResponse);
    } else {
      menuProductListRef.current = [];
      setMenuProductList([]);
    }
  }, [categoryService, companyId, productService]);

  useEffect(() => {
    if (companyId !== 0) {
      setCount((ct) => {
        if (ct === 0) {
          initLoad();
        }
        return ct;
      });
    }

    return () => {};
  }, [companyId]);

  function changeCategory(categoryId: number) {
    setMenuProductList(
      menuProductListRef.current.filter((x) => x.menuCategoryId === categoryId)
    );
  }
  function fullMenuProductList() {
    setMenuProductList(menuProductListRef.current);
  }
  return (
    <div style={{ flexDirection: "row" }}>
      <CategoryComponent
        items={categoryList}
        clickSelect={changeCategory}
        clickFullProducts={fullMenuProductList}
      />
      <Suspense fallback={<div>Loading...</div>}>
        <Restaurants menuProducts={menuProductList} />
      </Suspense>
    </div>
  );
};

export default ProductTabs;

interface ProductTabsPorp {}
interface RestaurantProp {
  menuProducts: MenuProductModel[];
}
