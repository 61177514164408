import React, { useCallback, useEffect, useMemo, useState } from "react";
import AdminCompanyUserAdd from "../../admins/company/AdminCompanyUserAdd";
import { Button, Checkbox, Modal, Table, TableColumnsType } from "antd";
import { Provider, useSelector } from "react-redux";
import { store } from "../../../react-reduxs/configure_store";
import dayjs from "dayjs";
import { UserListModel } from "../../../services/users/models/user_list.model";
import Title from "antd/es/typography/Title";
import { UserService } from "../../../services/users/user.service";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";

const CompManagerUserList = () => {
  const companyId = useSelector(getCompanyId);
  const userService = useMemo(() => new UserService(), []);
  const [data, setData] = useState<UserListModel[]>([]);
  const [count, setCount] = useState(0);
  const columns: TableColumnsType<UserListModel> = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Kullanıcı Adı",
      dataIndex: "userName",
    },
    {
      title: "E-Posta",
      dataIndex: "email",
    },
    {
      title: "Kullanıcı Grubu",
      dataIndex: "groupName",
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdDate",
      render: (value) => {
        return <>{dayjs(value).format("DD/MM/YYYY HH:mm")}</>;
      },
    },
    {
      title: "Güncelleme Tarihi",
      dataIndex: "updatedDate",
      render: (value) => {
        return <>{dayjs(value).format("DD/MM/YYYY HH:mm")}</>;
      },
    },
    {
      width: 50,
      title: "Kullanıcı Durumu",
      dataIndex: "id",
      render: (value, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              onClick={async () => {
                if (record.deletedDate === undefined) {
                  await userService.disableUser(value, dayjs().format());
                  await getUserList();
                } else {
                  await userService.disableUser(value, null);
                  await getUserList();
                }
              }}
              checked={record.deletedDate === undefined}
            />
          </div>
        );
      },
    },
  ];
  const getUserList = useCallback(async () => {
    let response = await userService.getListByCompanyId(companyId);

    if (response) {
      setData(response);
    }
  }, [companyId, userService]);
  useEffect(() => {
    if (companyId !== 0) {
      setCount((cou) => {
        if (cou === 0) {
          getUserList();
        }
        return 1;
      });
    }

    return () => {};
  }, [companyId, getUserList]);

  return (
    <div>
      <Title level={3} style={{ color: "#4A5967", textAlign: "center" }}>
        Kullanıcı Listesi
      </Title>
      <Button
        size="small"
        type="primary"
        onClick={() => {
          Modal.info({
            okType: "danger",
            okText: "İptal",
            content: (
              <Provider store={store}>
                <AdminCompanyUserAdd
                
                  companyId={companyId}
                  getList={() => getUserList()}
                  compManager={true}
                />
              </Provider>
            ),
          });
        }}
      >
        Kullanıcı Ekle
      </Button>
      <Table columns={columns} dataSource={data} rowKey={"id"} />
    </div>
  );
};

export default CompManagerUserList;
