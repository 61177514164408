import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GroupModel } from "../../../services/groups/models/group.model";
import Title from "antd/es/typography/Title";
import { GroupService } from "../../../services/groups/group.service";
import { UserService } from "../../../services/users/user.service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

interface AdminCompanyUserAddProps {
  compManager?: boolean;
  companyId: number;
  getList?:()=>void;
}

const AdminCompanyUserAdd: React.FC<AdminCompanyUserAddProps> = ({
  companyId,
  compManager = false,
  getList
}) => {
  const dispatch = useDispatch();
  const groupService = useMemo(() => new GroupService(), []);
  const userService = useMemo(() => new UserService(), []);
  const [form] = Form.useForm();
  const [groupList, setGroupList] = useState<GroupModel[]>([]);
  const [count, setCount] = useState(0);

  const getGroupList = useCallback(async () => {
    let response = await groupService.list();
    if (response) {
      setGroupList(response);
    }
  }, [groupService]);

  const initLoad = useCallback(async () => {
    await getGroupList();
  }, [getGroupList]);

  useEffect(() => {
    setCount((cou) => {
      if (cou === 0) {
        initLoad();
      }
      return 1;
    });
    return () => {};
  }, [initLoad]);

  const onFinish = async (values: any) => {
    console.error(values, companyId);
    let response = await userService.addUserCompany({
      ...values,
      companyId: companyId,
    });
    if (response) {
      toast.success("Kayıt işlemi başarılı");

      if (getList!==undefined) {
        getList();
      }
      form.resetFields();
    }
  };
  const companyManagerList = useCallback(() => {
    let data = groupList
      .filter((x) => x.id === 1 || x.id === 3)
      .map((x) => {
        return { value: x.id, label: x.groupName };
      });
    return data ?? [];
  }, [groupList]);

  const onFinishFailed = () => {};

  return (
    <div>
      <Title level={3}>Kullanıcı Ekle</Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row style={{ gap: 3 }}>
          <Col flex={1}>
            <Form.Item
              name="userName"
              label="Kullanıcı Adı"
              rules={[{ required: true }]}
            >
              <Input placeholder="Kullanıcı Adı" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              name="email"
              label="E-Posta"
              rules={[{ required: true }]}
            >
              <Input placeholder="E-Posta" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              name="password"
              label="Şifre"
              rules={[{ required: true }]}
            >
              <Input.Password placeholder="Şifre" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              name="groupId"
              label="Kullanıcı Grubu"
              rules={[{ required: true }]}
            >
              <Select
                placeholder=""
                style={{ width: "100%", minWidth: 150 }}
                options={
                  compManager
                    ? companyManagerList()
                    : groupList.map((item) => ({
                        value: item.id,
                        label: item.groupName,
                      }))
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Kaydet
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminCompanyUserAdd;
