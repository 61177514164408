import BaseApiService from "../base_api.service";
import { OrderProductStatusModel } from "./models/order_product_status.model";

export class OrderProductStatusService extends BaseApiService{
    /**
     *
     */
    constructor() {
        super("OrderProductStatuses");
    }
    async getList():Promise<OrderProductStatusModel[]>{
        return await this.get<OrderProductStatusModel[]>(``)??[];
    }
    
}