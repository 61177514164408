import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Avatar, Button, MenuProps, Row, Col, Image } from "antd";
import React, { useCallback } from "react";
import BasketBadgeComponent from "./components/BasketBadgeComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  removeBasket,
  selectBasketList,
} from "../../react-reduxs/slices/baskets/basker-slice";
import { AppDispatch } from "../../react-reduxs/configure_store";
import "./basket.css";
import UiDropdownList from "../../theme/ui-components/dropdowns/UiDropdownList";
import BasketOrdersSendComponent from "./components/BasketOrdersSendComponent";
import BasketEditButtonComponent from "./components/BasketEditButtonComponent";
import { ApiUrl } from "../../consts/api";

interface BasketComponentProps {
  color?: string;
}
const BasketComponent: React.FC<BasketComponentProps> = ({
  color = "black",
}) => {
  // const [total, setTotal] = useState(second)

  const dispatch: AppDispatch = useDispatch();
  const basketList = useSelector(selectBasketList);

  const handleMenuClick: MenuProps["onClick"] = (e) => {
   
  };
  const basketTotal = useCallback(() => {
    let total = 0;
    basketList.forEach((element) => {
      total += element.price * element.amount;
    });
    return total;
  }, [basketList]);

  const items: MenuProps["items"] = [
    ...basketList.map((x) => {
      return {
        style: { width: "100%", minWidth: "100%", paddingTop: 10 },
        label: (
          <Badge
            styles={{ root: { width: "-webkit-fill-available" } }}
            count={x.amount}
          >
            <Row
              style={{
                flexWrap: "nowrap",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 5,
              }}
            >
              <Col>
                <Image
                  src={ApiUrl+"/"+x.menuProductPhoto}
                  style={{ objectFit: "cover" }}
                  height={70}
                  width={70}
                />
              </Col>
              <Col flex={2}>
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 700,
                    paddingLeft: 10,
                    paddingTop: 20,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "186px",
                    textOverflow: "ellipsis",
                    padding: 0,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  {x.menuProductName}
                </div>
                <div
                  style={{
                    maxWidth: 200,
                    wordWrap: "break-word",
                    maxHeight: 70,
                    overflowY: "auto",
                  }}
                >
                  <p style={{ margin: 0 }}>{x.orderNote} </p>
                </div>
                <p style={{ margin: 0, fontWeight: "bold" }}>{x.price} ₺</p>
              </Col>
              <Col style={{ maxWidth: "17px", marginTop: "10px" }}>
                <Button
                  type="text"
                  danger
                  onClick={() => {
                    dispatch(removeBasket({ id: x.menuProductId }));
                  }}
                  icon={<i className="fa-duotone fa-circle-xmark fa-xl"></i>}
                ></Button>
                <BasketEditButtonComponent basketModel={x} />
              </Col>
            </Row>
          </Badge>
        ),
        key: x.menuProductId,
      };
    }),
    {
      label: (
        <Row>
          <Col style={{ fontWeight: "bold",paddingTop:4.3,marginRight:4 }}>Toplam:</Col>
          <Col style={{ alignContent: "center" }} flex={1}>
            {basketTotal().toFixed(2)} ₺
          </Col>
          <Col>
            <BasketOrdersSendComponent basketList={basketList} />
          </Col>
        </Row>
      ),
      key: 0,
    },
  ];
  return (
    <>
      <UiDropdownList items={items} onClick={handleMenuClick}>
        <BasketBadgeComponent>
          <Avatar
            style={{ backgroundColor: "white" }}
            shape="square"
            icon={
              <i
                style={{ color: color }}
                className="fa-duotone fa-cart-plus"
              ></i>
            }
          />
        </BasketBadgeComponent>
      </UiDropdownList>
    </>
  );
};

export default BasketComponent;
