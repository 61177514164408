import Title from "antd/es/typography/Title";
import React from "react";

const AdminCompManagerHome = () => {
  return (
    <>
      <div
        style={{
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 30,
        }}
      >
        <img width={200} height={200} alt="" src="/gifs/settings.gif" />
        <Title>Yapım aşamasında... </Title>
        <br />
      </div>
    </>
  );
};

export default AdminCompManagerHome;
