import { Button, Checkbox, Modal, Table, TableColumnsType } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CompanyPublicModel } from "../../../services/companies/models/company_public.model";
import Title from "antd/es/typography/Title";
import AdminCompanyAdd from "./AdminCompanyAdd";
import { CompanyService } from "../../../services/companies/company.service";
import { CompanyModel } from "../../../services/companies/models/company.model";
import { toast } from "react-toastify";
import AdminCompanyUserAdd from "./AdminCompanyUserAdd";
import { Provider } from "react-redux";
import { store } from "../../../react-reduxs/configure_store";

const AdminCompanyList: React.FC = () => {
  const companyService = useMemo(() => new CompanyService(), []);
  const id = useRef(0);
  const [openModalCompanyAdd, setOpenModalCompanyAdd] =
    useState<boolean>(false);
  const [data, setData] = useState<CompanyPublicModel[]>([]);
  const [count, setCount] = useState(0);

  const getCompanies = useCallback(async () => {
    let response = await companyService.getList();
    if (response) {
      setData(response);
    }
  }, [companyService]);

  const addCompany = useCallback(
    async (companyModel: CompanyModel) => {
      if (companyModel.id === 0) {
        let response = await companyService.add(companyModel);
        if (response) {
          toast.success("Kayıt işlemi başarılı");
          await getCompanies();
        }
        return response;
      } else {
        let response = await companyService.update(companyModel);
        if (response) {
          toast.success("Kayıt işlemi başarılı");
          await getCompanies();
        }
        return response;
      }
    },
    [companyService, getCompanies]
  );

  const initialLoad = useCallback(async () => {
    await getCompanies();
  }, [getCompanies]);

  useEffect(() => {
    try {
      setCount((cou) => {
        if (cou === 0) {
          initialLoad();
        }
        return 1;
      });
    } catch (error) {}

    return () => {};
  }, [initialLoad]);

  const columns: TableColumnsType<CompanyPublicModel> = [
    {
      title: "Başlık",
      dataIndex: "title",
    },
    {
      title: "Garson Çağır",
      dataIndex: "waiterCall",
      render: (value) => {
        return <Checkbox checked={value} />;
      },
    },
    {
      title: "Şiparis izini",
      dataIndex: "orderAtTheTable",
      render: (value) => {
        return <Checkbox checked={value} />;
      },
    },
    {
      width: 50,
      title: "",
      dataIndex: "id",
      render: (value) => {
        return (
          <Button
            type="text"
            onClick={() => {
              id.current = value;
              setOpenModalCompanyAdd(true);
            }}
            icon={
              <i
                style={{ color: "green", fontSize: "large" }}
                className="fa-duotone fa-pen-to-square"
              ></i>
            }
          />
        );
      },
    },
    {
      width: 50,
      title: "",
      dataIndex: "id",
      render: (value) => {
        return (
          <Button
            type="text"
            onClick={() => {
               Modal.info({
                okText:"Kapat",
                content:   <Provider store={store}><AdminCompanyUserAdd companyId={value} /></Provider>,
              });
              
            }}
            icon={
              <i
                style={{ color: "#1677FF", fontSize: "large" }}
                className="fa-duotone fa-user-plus"
              ></i>
            }
          />
        );
      },
    },
  ];
  return (
    <div>
      <Title level={3} style={{ color: "#4A5967", textAlign: "center" }}>
        Firma Listesi
      </Title>
      <Button
        onClick={() => {
          id.current = 0;
          setOpenModalCompanyAdd(true);
        }}
        size="small"
        style={{ margin: 10 }}
        icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
      >
        Yeni Firma Ekle
      </Button>
      <Table columns={columns} dataSource={data} rowKey={(value) => value.id} />
      {openModalCompanyAdd ? (
        <Modal
          title=""
          open={openModalCompanyAdd}
          okText={undefined}
          cancelText={undefined}
          onCancel={() => setOpenModalCompanyAdd(false)}
          styles={{ mask: { backdropFilter: "blur(10px)" } }}
          width={"80%"}
          footer={<></>}
        >
          <AdminCompanyAdd
            companyService={companyService}
            add={async (value) => {
              let response = await addCompany(value);
              return response;
            }}
            id={id.current}
          />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AdminCompanyList;
