import { Button, Form, Input, Modal } from "antd";
import React, { useCallback, useState } from "react";
import { BasketModel } from "../../../models/baskets/basket.model";
import { useTranslation } from "react-i18next";
import { I18nPagePropertyEnum } from "../../../i18n/i18n_page_property";
import CounterComponent from "../../../theme/ui-components/counter/CounterComponent";
import { AppDispatch } from "../../../react-reduxs/configure_store";
import { useDispatch } from "react-redux";
import { updateBasket } from "../../../react-reduxs/slices/baskets/basker-slice";

interface BasketEditButtonComponentPorps {
  basketModel: BasketModel;
}
const BasketEditButtonComponent: React.FC<BasketEditButtonComponentPorps> = ({
  basketModel,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [amount, setAmount] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = () => {
    setAmount(basketModel.amount);
    form.setFieldValue("orderNote", basketModel.orderNote);
    setIsModalOpen(true);
  };

  const increment = useCallback(() => {
    setAmount(amount + 1);
  }, [amount]);
  const descrement = useCallback(() => {
    if (amount < 2) {
      return;
    }
    setAmount(amount - 1);
  }, [amount]);
  const onFinish = () => {
    let newBasketModel = { ...basketModel };
    newBasketModel.orderNote = form.getFieldValue("orderNote");
    newBasketModel.amount = amount;
    console.error(newBasketModel);

    dispatch(updateBasket(newBasketModel));
    setIsModalOpen(false)
  };
  return (
    <div>
      <Button
        onClick={() => {
          showModal();
        }}
        type="text"
        icon={
          <i
            style={{ color: "green" }}
            className="fa-duotone fa-pen-to-square fa-xl"
          ></i>
        }
      ></Button>
      {isModalOpen ? (
        <Modal
          title={basketModel.menuProductName + " "}
          open={isModalOpen}
          footer={null}
          onCancel={() => {
            setIsModalOpen(false);
          }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="orderNote"
              label={t(I18nPagePropertyEnum.menuProductOrderNote)}
            >
              <Input.TextArea
                placeholder={t(I18nPagePropertyEnum.menuProductOrderNote)}
              />
            </Form.Item>
            <Form.Item
              name="amount"
              label={t(I18nPagePropertyEnum.commonAmount)}
            >
              <CounterComponent
                count={amount}
                increment={() => increment()}
                descrement={() => descrement()}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BasketEditButtonComponent;
