import { WaiterModel } from "../../models/waiter.model";
import BaseApiService from "../base_api.service";

export class WaiterService extends BaseApiService {
  /**
   *
   */
  constructor() {
    super("WaiterCalls");
  }

  async getWaiterCallListByCompanyId(
    companyId: number
  ): Promise<WaiterModel[]> {
    return await this.get<WaiterModel[]>(`?companyId=${companyId}`)??[];
  }
}
