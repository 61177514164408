import BaseApiService from "../base_api.service";
import { OrderStatusModel } from "./models/order_status.model";

export class OrderStatusService extends BaseApiService {
  constructor() {
    super("OrderStatuses");
  }

  async getList(): Promise<OrderStatusModel[]> {
    return (await this.get<OrderStatusModel[]>(``)) ?? [];
  }
  
}
