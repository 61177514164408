import BaseApiService from "../base_api.service";
import { GroupModel } from "./models/group.model";

export class GroupService extends BaseApiService {
  /**
   *
   */
  constructor() {
    super("Groups");
  }
  addGroup(groupModel: GroupModel): Promise<GroupModel | undefined> {
    return this.post<GroupModel>(``, groupModel);
  }
  async list(): Promise<GroupModel[]> {
    var response = await this.get<GroupModel[]>(``);
    return response ?? [];
  }
  deleteById(id: number): Promise<string | undefined> {
    return this.delete<string>(`/${id}`);
  }
}
