import { theme, Layout, Menu, Row, Col } from "antd";
import { Header, Content } from "antd/es/layout/layout";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { I18nPagePropertyEnum } from "../../i18n/i18n_page_property";
import "../../enums/orders/order_status.enum";
import UserProfileComponent from "../../components/UserProfileComponent";

const WaiterLayout: React.FC = () => {
 

  const { t } = useTranslation();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();



  return (
    <Layout style={{ overflow: "hidden" }}>
      <Header style={{ padding: "0px 10px 0px 0px" }}>
        <Row>
          <Col flex={1}>
            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={["1"]}
              style={{ width: "calc(100% - 129px)" }}
              items={[
                {
                  key: "1",
                  icon: <i className="fa-duotone fa-plate-utensils"></i>,
                  label: t(I18nPagePropertyEnum.waiterLayoutOrder),
                },
              ]}
            />
          </Col>
          <Col style={{ display: "flex", alignItems: "center" }}>
            <UserProfileComponent />
          </Col>
        </Row>
      </Header>
      <Content
        style={{
          padding: 4,
          minHeight: "80vh",
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Outlet />
      </Content>
    </Layout>
  );
};

export default WaiterLayout;
